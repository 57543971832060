// usePusher.js
import { ref, watch, onBeforeUnmount } from 'vue';
import { storeToRefs } from "pinia";
import Pusher from 'pusher-js';

import { useAuthStore } from "@/stores/AuthStore";
import { useUserNotificationStore } from '@/stores/UserNotificationsStore';

export default function usePusher() {
  const authStore = useAuthStore();
  const userNotificationsStore = useUserNotificationStore();
  const { currentUser } = storeToRefs(authStore);

  const pusherClient = ref(null);
  const globalChannel = ref(null);
  const personalChannel = ref(null);

  function initPusher() {
    if (!currentUser.value) return;
    if (pusherClient.value) {
      console.log('[usePusher] Pusher already initialized.')
    }
    console.log('[usePusher] Connecting to Pusher...')
    const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      cluster: 'us2',
      channelAuthorization: {
        endpoint: process.env.VUE_APP_PUSHER_AUTH_URL,
        transport: 'ajax',
        headers: {
          'Authorization': `Bearer ${currentUser.value.accessToken}`,
        },
      },
    });

    pusherClient.value = pusher;
    connectToGlobalChannel()
    connectToPrivateUserChannel()
  }
  function connectToGlobalChannel() {
    if (!currentUser.value) return;
    console.log('[usePusher] Connecting to Global Channel...')

    globalChannel.value = pusherClient.value.subscribe('global');

    globalChannel.value.bind('test', (data) => {
      console.log('[usePusher] Global Test event received.')
    });
  }

  function connectToPrivateUserChannel() {
    if (!currentUser.value) return;
    console.log('[usePusher] Connecting to Private User Channel...')

    personalChannel.value = pusherClient.value.subscribe(`private-user@${currentUser.value.id}`);

    personalChannel.value.bind('new_user_notification', () => {
      console.log('[usePusher] New user notification received.')
      userNotificationsStore.fetchUserNotifications();
    });
  }

  // This function is responsible for the cleanup
  function disconnectFromPusher() {
    if (globalChannel.value) {
      globalChannel.value.unbind_all();
      pusherClient.value.unsubscribe('global');
    }

    if (personalChannel.value) {
      personalChannel.value.unbind_all();
      pusherClient.value.unsubscribe(`private-user@${currentUser.value.id}`);
    }

    if (pusherClient.value) {
      pusherClient.value.disconnect();
    }
  }

  watch(currentUser, initPusher);
  onBeforeUnmount(disconnectFromPusher);

  return {
    pusherClient,
    globalChannel,
    personalChannel,
  };
}
