<script setup>
import { defineProps, computed, onBeforeMount, ref } from "vue";
import { truncate } from "lodash";
import { useTimeAgo } from "@vueuse/core";
import { ChevronRightIcon } from "@heroicons/vue/24/solid";
import CountBadge from "@/components/Atoms/CountBadge";
import { useCurrentUser } from "@/composables";
import { MessageService } from "@/services";
import { useChatStore } from "@/stores/ChatStore";
import UserAvatar from "./Atoms/UserAvatar.vue";

const currentUser = useCurrentUser();
const chatStore = useChatStore();

const props = defineProps({
  conversation: {
    type: Object,
    required: true,
  },
});

const lastMessage = ref(null);

onBeforeMount(() => {
  if (props.conversation.lastMessageId) {
    MessageService.fetchOneByConversation({
      conversationId: props.conversation.id,
      messageId: props.conversation.lastMessageId,
    })
      .then(({ data }) => {
        lastMessage.value = data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

const members = computed(() => {
  return props.conversation.users.map((member) => {
    return {
      ...member,
      isCurrenUser: member.id === currentUser.value.id,
    };
  });
});
const membersNotCurrentUser = computed(() => {
  return members.value.filter((member) => {
    return member.id !== currentUser.value.id;
  });
});
const memberNames = computed(() => {
  return membersNotCurrentUser.value
    .map((member) => member.fullName)
    .join(", ");
});

const unseenCount = computed(() => {
  return chatStore.unreadMessages.filter(
    (message) => message.conversationId == props.conversation.id
  ).length;
});
const hasUnseenMessages = computed(() => {
  return unseenCount.value > 0;
});
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <li v-if="props.conversation && currentUser">
    <router-link
      :to="{
        name: 'ConversationView',
        params: { conversationId: props.conversation.id },
      }"
      class="block hover:bg-gray-50"
    >
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex -space-x-3 overflow-hidden">
            <UserAvatar
              v-for="member in membersNotCurrentUser"
              :key="member.id"
              :user="member"
            />
          </div>
          <div class="min-w-0 flex-1 px-4">
            <div>
              <p
                :class="[
                  hasUnseenMessages ? 'font-bold' : 'font-medium',
                  'text-teal-500',
                ]"
              >
                {{ memberNames }}
              </p>
              <p
                v-if="lastMessage"
                :class="[
                  hasUnseenMessages
                    ? 'font-bold text-slate-500'
                    : 'font-normal text-gray-500',
                  'flex items-center text-xs',
                ]"
              >
                <span
                  v-if="lastMessage.author.id == currentUser.id"
                  class="mr-1"
                >
                  You:
                </span>
                "{{ truncate(lastMessage.body, { length: 18 }) }}" &#183;
                {{ useTimeAgo(props.conversation.updatedAt).value }}
              </p>
            </div>
          </div>
        </div>
        <CountBadge v-if="unseenCount > 0" :count="unseenCount" class="mr-5" />
        <div>
          <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      </div>
    </router-link>
  </li>
</template>
