<script setup>
  import { onMounted, onBeforeUnmount, computed } from "vue";
  import { storeToRefs } from "pinia";
  import { useAuthStore } from "@/stores/AuthStore";
  import { useChatStore } from "@/stores/ChatStore";
  import { useUserNotificationStore } from "@/stores/UserNotificationsStore";
  import { useCheckInsPendingReviewQuery } from "@/queries/checkIns/useCheckInsPendingReviewQuery";
  import { useLDReady, useLDFlag } from 'launchdarkly-vue-client-sdk'

  const ldReady = useLDReady()
  const allowViewVideos = useLDFlag('coach-videos.allow-viewing', false)
  const allowMealPlanViewing = useLDFlag('meal-plans.allow-viewing', false)
  const workoutTemplatesAllowCreateFlag = useLDFlag('workout-templates.allow-create', false)
  const workoutsAllowFlag = useLDFlag('workouts.allow', false)

  import MainNavLink from "@/components/Atoms/MainNavLink.vue";
  import SearchDialogButton from "@/components/Atoms/SearchDialogButton.vue";

  const appVersion = process.env.VUE_APP_VERSION;
  const chatStore = useChatStore();
  const authStore = useAuthStore();
  const userNotificationsStore = useUserNotificationStore();

  const emit = defineEmits(["navClick"]);
  const { unreadMessages } = storeToRefs(chatStore);
  const UNREAD_MESSAGES_POLL_INTERVAL = process.env.NODE_ENV === "development" ? 60 * 1000 * 5 : 60 * 1000;
  let notificationCountPoller = null;

  const { data: checkInsPendingReview } = useCheckInsPendingReviewQuery()

  const checkInBadgeCount = computed(() => {
    return checkInsPendingReview?.value.length || 0;
  });

  function fetchBadgeCountData() {
    chatStore.fetchUnreadMessages();
  }

  onMounted(() => {
    setTimeout(fetchBadgeCountData, 3000);
    notificationCountPoller = setInterval(fetchBadgeCountData, UNREAD_MESSAGES_POLL_INTERVAL);
  });

  onBeforeUnmount(() => {
    clearInterval(notificationCountPoller);
  });

  const messageBadgeCount = computed(() => {
    if (!unreadMessages.value) return 0;
    return unreadMessages?.value?.length || 0;
  });

  const resourceNotificationsCount = computed(() => {
    if (ldReady.value && !allowViewVideos.value) return 0;
    return userNotificationsStore.resourceNotifications.length
  });

  const clientCheckInLink = computed(() => {
    if (authStore.currentUser.trainingPrograms?.length == 1) {
      return {
        name: "TrainingPrograms#CheckIn",
        params: {
          trainingProgramId: authStore.currentUser.trainingPrograms[0].id,
        },
      };
    } else {
      return { name: "TrainingPrograms" };
    }
  });

  const allowWorkoutTemplatesNavigation = computed(() => {
    return ldReady.value && workoutTemplatesAllowCreateFlag.value;
  });

  const allowClientWorkoutsNavigation = computed(() => {
    return ldReady.value && workoutsAllowFlag.value;
  });
</script>

<template>
  <nav
    class="mt-5 flex-1 px-2 space-y-1"
    @click="emit('navClick')"
  >
    <SearchDialogButton
      v-if="authStore.isTrainer"
      class="mb-5 hidden md:inline-flex"
    />
    <!-- DASHBOARD -->
    <MainNavLink
      id="main-nav__dashboard-link"
      :to="{ name: 'Dashboard' }"
      label="Dashboard"
      icon="fa-regular fa-house"
    />
    <!-- CLIENT CHECK-IN -->
    <MainNavLink
      v-if="authStore.isClient"
      id="main-nav__client-check-in-link"
      :to="clientCheckInLink"
      label="Check In"
      icon="fa-regular fa-clipboard-check"
    />
    <!-- CLIENT DO WORKOUT -->
    <MainNavLink
      v-if="authStore.isClient && allowClientWorkoutsNavigation"
      id="main-nav__client-my-workouts-link"
      :to="{ name: 'WorkoutTemplates' }"
      label="Workout"
      icon="fa-regular fa-dumbbell"
    />

    <!-- CLIENT MY CHECK-INS -->
    <MainNavLink
      v-if="authStore.isClient"
      id="main-nav__client-my-check-ins-link"
      :to="{ name: 'CheckIns' }"
      label="My Check-Ins"
      icon="fa-regular fa-rectangle-history"
    />

    <MainNavLink
      v-if="authStore.isClient && allowClientWorkoutsNavigation"
      id="main-nav__client-my-workouts-link"
      :to="{ name: 'Workouts' }"
      label="My Workouts"
      icon="fa-regular fa-trophy-star"
    />
    <MainNavLink
      v-if="authStore.isClient && allowMealPlanViewing"
      id="main-nav__client-ai-meal-plans"
      :to="{ name: 'MealPlans' }"
      label="Meal Plan AI"
      icon="fa-regular fa-sparkles"
    />
    <!-- TRAINER CHECKINS -->
    <MainNavLink
      v-if="authStore.isTrainer"
      id="main-nav__coach-check-ins-link"
      :to="{ name: 'CheckIns' }"
      label="Check Ins"
      icon="fa-regular fa-clipboard-check"
      :notifications-count="checkInBadgeCount"
    />

    <!-- TRAINER MY CLIENTS -->
    <MainNavLink
      v-if="authStore.isTrainer"
      id="main-nav__coach-my-clients-link"
      :to="{ name: 'ActiveClientsPage' }"
      label="My Clients"
      icon="fa-regular fa-user-group"
    />

    <!-- TRAINER MY TRAINING PROGRAMS -->
    <MainNavLink
      v-if="authStore.isTrainer"
      id="main-nav__coach-my-training-programs-link"
      :to="{ name: 'TrainingPrograms' }"
      label="My Training Programs"
      icon="fa-regular fa-fire"
    />
    <!-- TRAINER MY CHECK-IN TEMPLATES -->
    <MainNavLink
      v-if="authStore.isTrainer"
      id="main-nav__coach-my-templates-link"
      :to="{ name: 'MyTemplates' }"
      label="My Templates"
      icon="fa-regular fa-rectangle-history"
    />
    <!-- COACH WORKOUT TEMPLATES -->
    <MainNavLink
      v-if="authStore.isTrainer && allowWorkoutTemplatesNavigation"
      id="main-nav__coach-workouts-templates-link"
      :to="{ name: 'WorkoutTemplates' }"
      label="Workout Templates"
      icon="fa-regular fa-dumbbell"
    />
    <div class="pt-2" />
    <div class="block pt-2 border-t border-slate-900" />
    <MainNavLink
      id="main-nav__inbox-link"
      :to="{ name: 'Inbox' }"
      label="Inbox"
      icon="fa-regular fa-comments"
      :notifications-count="messageBadgeCount"
    />
    <div class="pt-2" />
    <div class="block pt-2 border-t border-slate-900" />
    <MainNavLink
      id="main-nav__resources-link"
      :to="{ name: 'Resources' }"
      label="Resources"
      :notifications-count="resourceNotificationsCount"
    />
    <MainNavLink
      id="main-nav__my-profile-link"
      :to="{ name: 'MyProfile' }"
      label="My Profile"
    />
    <MainNavLink
      id="main-nav__logout-link"
      :to="{ name: 'Logout' }"
      label="Logout"
    />
    <p class="text-gray-600 text-xs pt-10 pl-2">
      v{{ appVersion }}
    </p>
  </nav>
</template>
