import { createApp } from "vue";
import { plugin, defaultConfig } from "@formkit/vue";
import { createPinia } from "pinia";
import { VueQueryPlugin } from "@tanstack/vue-query";


import "./assets/tailwind.css";
import formkitConfig from "../formkit.config.js";
import router from "@/routes/vueRouter";
import App from "./App.vue";
import { useTitle } from "@vueuse/core";
import { Capacitor } from "@capacitor/core";
import Toast from "vue-toastification";

import { LDPlugin } from 'launchdarkly-vue-client-sdk'
import * as Sentry from "@sentry/vue";

import { PushNotificationService } from "./services";

import { IonicVue } from "@ionic/vue";

import fontAwesomeIcons from './fontAwesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import "vue-toastification/dist/index.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
// import "@ionic/vue/css/normalize.css";
// import "@ionic/vue/css/structure.css";
// import "@ionic/vue/css/typography.css";

// /* Optional CSS utils that can be commented out */
// import "@ionic/vue/css/padding.css";
// import "@ionic/vue/css/float-elements.css";
// import "@ionic/vue/css/text-alignment.css";
// import "@ionic/vue/css/text-transformation.css";
// import "@ionic/vue/css/flex-utils.css";
// import "@ionic/vue/css/display.css";


import "./style.css";

const formkitPlugin = plugin;
const title = useTitle();
const pinia = createPinia();
const app = createApp(App);

library.add(fontAwesomeIcons)

title.value = "AK Fitness";
console.log("Client: ", Capacitor.isNativePlatform() ? "mobile" : "web");
console.log("Client version: ", process.env.VUE_APP_VERSION);
console.log("Environment: ", process.env.NODE_ENV);
console.log("API URL:", process.env.VUE_APP_API_BASE_URL);
console.log("ld:",  process.env.VUE_APP_LD_CLIENT_ID)

if (process.env.NODE_ENV === "production") {

  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: .25,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release: "akfit-client@" + process.env.VUE_APP_VERSION,
  });
}

if (Capacitor.isNativePlatform()) {
  PushNotificationService.initializePushNotifications().catch(err => {
    console.error("Push notification initialization error: ", err);
  });
}

app.use(VueQueryPlugin);
// eslint-disable-next-line
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(LDPlugin, { clientSideID: process.env.VUE_APP_LD_CLIENT_ID });
app.use(Toast, { position: "bottom-center" });
app.use(router);
app.use(IonicVue);
app.use(formkitPlugin, defaultConfig(formkitConfig))
app.use(pinia);
app.mount("#app");
