<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
import { useCurrentUser } from "@/composables";
import { ConversationService } from "@/services";
import BasicButton from "@/components/ui/BasicButton";

const router = useRouter();
const props = defineProps({
  userId: {
    type: String,
    required: true,
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
});
const currentUser = useCurrentUser();
const handleClick = () => {
  if (props.userId && currentUser.value.id) {
    ConversationService.findOrCreateByUserIds({
      userIds: [props.userId, currentUser.value.id],
    })
      .then(({ data }) => {
        router.push({
          name: "ConversationView",
          params: { conversationId: data.id },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
</script>

<template>
  <BasicButton icon="chat" secondary @click="handleClick">
    Chat
  </BasicButton>
</template>
@/components/ui/BasicButton
