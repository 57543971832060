import http from "@/services/http";

function create({ data }) {
  return http.post(`/check_in_templates`, data);
}

function update({ templateId, data }) {
  return http.patch(`/check_in_templates/${templateId}`, {
    title: data.title,
    includes: data.includes,
    default: data.default,
  });
}

function fetchAllByTrainer() {
  return http.get(`/check_in_templates`);
}

function findOne(id) {
  return http.get(`check_in_templates/${id}`);
}

function fetchDefaultByTrainer() {
  return http.get(`/check_in_templates?default=true`);
}

async function makeDefault({ trainerId, templateId }) {
  return await fetchDefaultByTrainer({ trainerId: trainerId }).then((res) => {
    const currentDefault = res.data[0];
    if (currentDefault) {
      return update({
        trainerId: trainerId,
        templateId: currentDefault.id,
        data: { default: false },
      }).then(() => {
        return update({
          trainerId: trainerId,
          templateId: templateId,
          data: { default: true },
        });
      });
    } else {
      return update({
        trainerId: trainerId,
        templateId: templateId,
        data: { default: true },
      });
    }
  });
}

export const CheckInTemplateService = {
  create,
  update,
  fetchAllByTrainer,
  findOne,
  fetchDefaultByTrainer,
  makeDefault,
};
