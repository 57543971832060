import http from "@/services/http";

export const ConversationService = {
  fetchAllForUser: () => {
    return http.get("/conversations");
  },
  fetchById: ({ conversationId }) => {
    return http.get(`/conversations/${conversationId}`);
  },
  findOrCreateByUserIds: ({ userIds }) => {
    return http.post("/conversations", { user_ids: userIds });
  },
};
