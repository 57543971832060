<script setup>
  import { computed } from "vue";
  import { useRoute } from "vue-router";
  import { useAuthStore } from "@/stores/AuthStore";
  import { storeToRefs } from "pinia";
  import NavSideBar from "@/components/PageElements/NavSideBar";
  import IonicMobileNavBar from "@/components/PageElements/IonicMobileNavBar.vue";
  import MobileNavBar from "@/components/PageElements/MobileNavBar.vue";
  import SearchDialog from "@/components/PageElements/SearchDialog.vue";
  import { useCapacitor } from '@/composables/useCapacitor';

  const { isCapacitor } = useCapacitor();

  const currentPage = useRoute();
  const authStore = useAuthStore();
  const currentUser = storeToRefs(authStore);

  const loading = computed(() => {
    if (currentPage.meta.public == true) {
      return false;
    }
    return currentUser == null;
  });
</script>

<template>
  <div
    v-if="!loading"
    class="pt-safe"
  >
    <NavSideBar />

    <IonicMobileNavBar v-if="isCapacitor" />
    <MobileNavBar v-if="!isCapacitor" />
    <SearchDialog v-if="authStore.isTrainer" />
    <main
      class="flex flex-col flex-1 px-3 pt-[80px] md:pt-0 md:pr-8 md:pl-[285px] pb-24"
      v-if="!isCapacitor"
    >
      <router-view />
    </main>
  </div>
</template>
