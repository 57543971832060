import { ref } from 'vue'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import gravatar from "gravatar.js";

const fetchGravatar = async (email) => {
  const url = gravatar.url(email, { size: 200 });
  const response = await fetch(url);
  if (response.status === 404) {
    return null;
  }
  return url;
};

export function useUserAvatarQuery(user) {
  const userAvatarUrl = ref(null);
  const queryClient = useQueryClient();

  const cachedAvatarUrl = queryClient.getQueryData(['userAvatar', user.email ])?.data;

  if (cachedAvatarUrl) {
    console.log('[Cache:Hit] Avatar')
    userAvatarUrl.value = cachedAvatarUrl;
  }
  
  useQuery({
    queryKey: ['gravatar', user.email],
    queryFn: () =>fetchGravatar(user.email),
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    onSuccess: (data) => {
      userAvatarUrl.value = data;
    },
  })

  return {
    userAvatarUrl
  };
}