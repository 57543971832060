<script setup>
import { defineProps, computed, onMounted } from "vue";
import Autolinker from "autolinker";
import { useTimeAgo } from "@vueuse/core";
import UserAvatar from "@/components/Atoms/UserAvatar.vue";
import { useCurrentUser } from "@/composables";
import { MessageService } from "@/services";
import { useChatStore } from "@/stores";

const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
});
const chatStore = useChatStore();
const currentUser = useCurrentUser();

onMounted(() => {
  if (!hasSeen.value) {
    MessageService.markAsSeen({
      conversationId: props.message.conversationId,
      messageId: props.message.id,
    }).then(() => chatStore.fetchUnreadMessages());
  }
});

const isAuthor = computed(() => {
  return props.message.author.id == currentUser.value.id;
});

const hasSeen = computed(() => {
  return props.message.seenBy.includes(currentUser.value.id);
});

const messageTime = computed(() => {
  return useTimeAgo(props.message.createdAt).value;
});

const minutesSincePreviousMessage = computed(() => {
  return (
    (new Date(props.message.createdAt).getTime() -
      new Date(props.message.previousTimestamp).getTime()) /
    1000 /
    60
  );
});

const showAvatar = computed(() => {
  return !isAuthor.value;
});

const showTimeStamp = computed(() => {
  return props.message.isLastMessage || minutesSincePreviousMessage.value > 5;
});

const showTinyName = computed(() => {
  return props.message.readReceipts.length > 2 && !isAuthor.value;
});

const parsedMessgeBody = computed(() => {
  return Autolinker.link(props.message.body, {
    stripPrefix: false,
    newWindow: true,
    truncate: {
      length: 150,
      location: "smart",
    },
  });
});

const classes = computed(() => {
  return {
    messageWrapper: [
      "flex",
      "items-end",
      isAuthor.value ? "justify-end" : "justify-start",
    ],
    tinyName: ["text-xs", "text-gray-600", "ml-[47px]"],
    chatBubbleWrapper: [
      "flex flex-col space-y-2 text-sm max-w-xs mx-2",
      isAuthor.value ? "order-1 items-end" : 'order-2 items-start"',
    ],
    chatBubble: [
      "px-4 py-2 rounded-lg inline-block",
      isAuthor.value
        ? "rounded-br-none bg-teal-600 text-white"
        : "rounded-bl-none bg-gray-300 text-gray-600",
    ],
    timeStamp: [
      "block text-xs text-gray-400 mt-1",
      isAuthor.value ? "text-right pr-2" : "ml-[47px]",
    ],
  };
});
</script>

<template>
  <div
    :class="[
      'chat-message',
      showTimeStamp && !props.message.isLastMessage ? 'pb-10' : '',
    ]"
  >
    <span v-if="showTinyName" :class="classes.tinyName">{{
      message.author.firstName
    }}</span>
    <div :class="classes.messageWrapper">
      <div :class="classes.chatBubbleWrapper">
        <span :class="classes.chatBubble">
          <span class="chat-message-body" v-html="parsedMessgeBody" />
        </span>
      </div>
      <UserAvatar
        v-if="showAvatar"
        :user="message.author"
        size="[30px]"
        class="order-1 mr-2"
      />
      <div
        v-if="!showAvatar && !isAuthor"
        class="h-[30px] w-[30px] order-1 mr-2"
      />
    </div>
    <span v-if="showTimeStamp" :class="classes.timeStamp">{{
      messageTime
    }}</span>
  </div>
</template>

<style lang="postcss">
.chat-message .chat-message-body a {
  text-decoration: underline;
}
</style>
