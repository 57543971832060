<script setup>
  import { useRoute, useRouter } from 'vue-router';

  const props = defineProps({
    tabs: {
      type: Array,
      required: true,
      validator: (value) => value.every(tab => 'label' in tab && 'route' in tab && 'iconClass' in tab)
    }
  });

  const router = useRouter();
  const currentRoute = useRoute();

  const isActive = (route) => {
    return currentRoute.name === route.name && JSON.stringify(currentRoute.params) === JSON.stringify(route.params);
  };

  const handleMobileNavChange = (event) => {
    const route = event.target.value;
    router.push({ name: route });
  };
</script>

<template>
  <div>
    <div class="sm:hidden">
      <select
        id="tabs"
        name="tabs"
        class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        @change="handleMobileNavChange"
        :value="currentRoute.name"
      >
        <option
          v-for="(tab, idx) in tabs"
          :key="idx"
          :value="tab.route.name"
        >
          {{ tab.label }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav
        class="isolate flex divide-x divide-gray-200 rounded-lg shadow"
        aria-label="Tabs"
      >
        <router-link
          v-for="(tab, tabIdx) in tabs"
          :key="tab.label"
          :to="tab.route"
          :class="[isActive(tab.route) ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center font-medium hover:bg-gray-50 focus:z-10']"
          :aria-current="isActive(tab.route) ? 'page' : undefined"
        >
          <span class="">
            <i
              v-if="tab.iconClass"
              :class="[tab.iconClass, 'text-base mb-1 sm:mb-0 sm:mr-1 text-slate-500']"
              class="hidden sm:inline-block"
            />
            {{ tab.label }}
          </span>
          <span
            aria-hidden="true"
            :class="[isActive(tab.route) ? 'bg-brand-primary-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']"
          />
        </router-link>
      </nav>
    </div>
  </div>
</template>
