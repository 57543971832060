import http from "./http.js";

export const ClientService = {
  invite: (payload) => {
    return http.post("/clients/invite", payload);
  },
  findOne: (id) => {
    return http.get(`/clients/${id}`);
  },
  fetchAllByTrainer: () => {
    return http.get("/clients");
  },
  fetchAllArchivedByTrainer: () => {
    return http.get("/clients/archived");
  },
  update: (client) => {
    return http.patch(`/clients/${client.id}`, client);
  },
  archiveOne: (id) => {
    return http.patch(`/clients/${id}`, { active: false });
  },
  unArchiveOne: (id) => {
    return http.patch(`/clients/${id}`, { active: true });
  },
  delete: (id) => {
    return http.delete(`/clients/${id}`);
  },
  search: (query) => {
    return http.get(`/clients/search?query=${query}`);
  },
};

export default ClientService;
