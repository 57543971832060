import http from "./http";

export const MEAL_PLANS_PATH = '/meal_plans'
export const NEW_MEAL_PLAN_PATH = MEAL_PLANS_PATH + '/new'

export const MealPlanService = {
  fetchAll: () => {
    return http.get(
      MEAL_PLANS_PATH
    );
  },
  generate: (numberOfMeals) => {
    return http.post(
      MEAL_PLANS_PATH,
      {number_of_meals: numberOfMeals}
    );
  },
  delete: (id) => {
    return http.delete(
      MEAL_PLANS_PATH + '/' + id
    );
  },
}


export default MealPlanService;
