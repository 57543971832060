<script setup>
  import { onBeforeMount, ref, computed } from "vue";
  import ErrorAlert from "@/components/Alerts/ErrorAlert.vue";
  import PageContainer from "@/components/Atoms/PageContainer.vue";
  import { CheckInTemplateService, ClientService } from "@/services/";
  import router from "@/routes/vueRouter";

  const checkInTemplates = ref([]);
  const errors = ref([]);

  onBeforeMount(() => {
    CheckInTemplateService.fetchAllByTrainer()
      .then(({ data }) => {
        checkInTemplates.value = data;
      })
      .catch((error) => {
        errors.value.push(error)
        errors.value.push("Failed to fetch check-in templates.");
      });
  });

  const checkInTemplateOptions = computed(() => {
    return checkInTemplates.value.map((template) => {
      return {
        value: template.id,
        label: template.title,
      };
    });
  });

  const submitHandler = (data) => {
    ClientService.invite(data)
      .then(({ data }) => {
        router.push({ name: "ViewClientPage", params: { id: data.id } });
      })
      .catch(({ response }) => {
        response.data.errors.forEach((error) => {
          errors.value.push(error);
        });
      });
  };
</script>

<template>
  <PageContainer>
    <FormKit
      type="form"
      :actions="false"
      @submit="submitHandler"
    >
      <FormKit
        type="email"
        label="Email"
        validation="required"
        name="email"
      />
      <div class="grid md:grid-cols-2 gap-4 grid-cols-1">
        <FormKit
          type="text"
          label="First Name"
          validation="required"
          name="firstName"
        />
        <FormKit
          type="text"
          label="Last Name"
          validation="required"
          name="lastName"
        />
        <FormKit
          v-if="checkInTemplateOptions.length != 0"
          type="select"
          validation="required"
          name="checkInTemplateId"
          :value="checkInTemplateOptions[0]['value']"
          :options="checkInTemplateOptions"
        />
      </div>
      <ErrorAlert
        v-if="errors.length > 0"
        :errors="errors"
      />
      <FormKit
        type="submit"
        label="Invite"
      />
    </FormKit>
  </PageContainer>
</template>
