<script setup>
  import { defineProps } from "vue";
  import { useRouter } from "vue-router";
  import { ChevronRightIcon } from "@heroicons/vue/24/outline";
  import {formatDate} from "@/utils/formatters";
  import IconWithLabel from "@/components/ui/IconWithLabel";
  import UserAvatar from "@/components/Atoms/UserAvatar.vue";
  import {Heading4} from  "@/components/ui/Headings"

  const props = defineProps({
    clients: {
      type: Array,
      required: true,
    },
  });

  const router = useRouter();
  function navigateToClient(clientId) {
    router.push({ name: 'ViewClientPage', params: { id: clientId } })
  }
</script>

<template>
  <ul
    v-if="clients"
    role="list"
    class="divide-y divide-gray-200"
  >
    <li
      v-for="client in props.clients"
      :key="client.id"
      class="cursor-pointer"
      @click="navigateToClient(client.id)"
    >
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0">
            <UserAvatar
              size="12"
              :user="client"
            />
          </div>
          <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <Heading4>
                {{ client.fullName }}
              </Heading4>
              <div class="flex">
                <IconWithLabel
                  v-if="client.lastCheckInAt"
                  icon="calendar"
                  class="w-24 sm:mt-0 md:mt-2"
                >
                  {{ formatDate(client.lastCheckInAt) }}
                </IconWithLabel>
                <IconWithLabel
                  v-if="client.email"
                  icon="envelope"
                  class="ml-4 hidden md:inline-flex sm:mt-0 md:mt-2"
                >
                  {{ client.email }}
                </IconWithLabel>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ChevronRightIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </li>
  </ul>
</template>

