import { useAuthStore } from "@/stores";
// workoutTemplatesRoutes.js
export default [
  {

    path: "/workout_templates",
    children: [
      {
        path:"",
        name: "WorkoutTemplates",
        component: function () {
          const authStore = useAuthStore();
          if (authStore.isTrainer) {
            return import("@/pages/WorkoutTemplates/CoachIndexPage.vue")
          } else {
            return import("@/pages/WorkoutTemplates/ClientIndexPage.vue");
          }
        },
      },
      {
        path: "new",
        name: "NewWorkoutTemplate",
        component: () => import("@/pages/WorkoutTemplates/NewPage.vue"),
      },
      {
        path: "new",
        name: "NewWorkoutTemplateFromTemplate",
        component: () => import("@/pages/WorkoutTemplates/NewFromTemplatePage.vue"),
      },

      {
        path: ":id/edit",
        name: "EditWorkoutTemplate",
        component: () => import("@/pages/WorkoutTemplates/EditPage.vue"),
      },
      {
        path: ":id/assign",
        name: "AssignWorkoutTemplate",
        component: () => import("@/pages/WorkoutTemplates/AssignPage.vue"),
        meta: {
          requireCoach: true,
        },
      },
    ],
  },
  {
    path: "/clients/:id/workout_templates",
    name: "WorkoutTemplates:Coach:ListClientWorkoutTemplatesPage",
    component: () => import("@/pages/WorkoutTemplates/Coach/ListClientWorkoutTemplatesPage.vue"),
    meta:{
      requireCoach: true,
    }
  },
];
