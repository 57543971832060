<template>
  <div :class="['flex-col text-sm font-medium justify-items-center items-center h-full', differenceColor]">
    <div class="flex justify-end h-4">
      <font-awesome-icon v-if="difference < 0" icon="fa-solid fa-caret-up" class="text-xl text-gray-50" />
      <font-awesome-icon v-if="difference > 0" icon="fa-solid fa-caret-up" class="text-xl opacity-80" />
    </div>

    {{ differenceSign }}{{ difference }}<span class="text-xs">{{ props.unit }}</span>

    <div class="flex justify-end h-4 -mt-1">
      <font-awesome-icon v-if="difference < 0" icon="fa-solid fa-caret-down" class="text-xl m-0 p-0 opacity-90"/>
      <font-awesome-icon v-if="difference > 0" icon="fa-solid fa-caret-down" class="text-xl m-0 p-0 text-gray-50"/>
    </div>
  </div>
</template>

<script setup>
import {defineProps, computed} from "vue";
const props = defineProps({
  currentStat: {
    type: Number,
    default: 0,
  },
  previousStat: {
    type: Number,
    default: 0,
  },
  unit: {
    type: String,
    default: "",
  }
});

const difference = computed(() => {
  return props.currentStat - props.previousStat;
});

const differenceColor = computed(() => {
  return difference.value > 0 ? "text-green-700" : "text-red-700";
})

const differenceSign = computed(() => {
  if (difference.value > 0) {
    return "+";
  }
  return "";
});

</script>
