import {
  createRouter,
  createWebHistory,
  isNavigationFailure,
} from "vue-router";
import { JwtService } from "@/services";
import { useAuthStore } from "@/stores";
import routes from "./routes";
import { routerLogger } from "@/utils/consoleLoggers";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to, from, failure) => {
  routerLogger("Navigation to:", to.path)
  if (isNavigationFailure(failure)) {
    routerLogger("Navigation FAILED:", failure);
  }
});

router.beforeEach(async (to, from, next) => {
  routerLogger("Navigation from:", from.path)

  const authStore = useAuthStore();
  const currentUserFromJwt = JwtService.currentJwtUser();

  function destroyJwtAndRedirect(query) {
    JwtService.destroyCookie();
    return next({ name: "Login", query: query });
  }

  if (to.path === "/logout") {
    return authStore
      .logout()
      .then(() => destroyJwtAndRedirect(to.query))
      .catch((error) => {
        console.log(error);
        destroyJwtAndRedirect(to.query);
      });
  }

  if (to.meta.public) {
    next();
    return;
  }

  if (currentUserFromJwt && JwtService.isExpired()) {
    console.log("jwt expired, redirecting to logout");
    next({ name: "Logout", query: { security_timeout: true } });
    return;
  }

  if (authStore.isLoggedIn) {
    console.log("isLoggedIn, moving on.");
    next();
    return;
  }

  if (currentUserFromJwt) {
    console.log("fetching user from jwt");
    if (!authStore.isLoggedIn) {
      console.log("hydrate route");
      await authStore.hydrateUserFromJwt().catch((err) => {
        console.log(err);
        next({ name: "Login" });
        return;
      });
    }
    console.log("isLoggedIn, moving on. (from jwt)");
    next();
  } else {
    console.log("no user, redirecting to login");
    next({ name: "Login" });
  }
  if (to.matched.some(record => record.meta.requireCoach)) {
    routerLogger("Checking For Coach Role");
    requireCoach(to, from, next);
  } else {
    next();
  }
});

export default router;
