<template>
  <div :class="classNames">
    <HeroIcon v-if="props.icon" :icon="props.icon" classes="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"/>
    <slot/>
  </div>
</template>

<script setup>
import {computed} from 'vue';
import HeroIcon from '@/components/ui/HeroIcon'

const props = defineProps({
  classes: {
    type: String,
    required: false,
    default: "",
  },
  icon: {
    type: String,
    required: false,
    default: null,
  },
});

const classNames = computed(() => {
  return ['flex items-center text-sm text-gray-400', props.classes];
});

</script>
@/components/ui/HeroIcon
