import { generateClasses } from "@formkit/themes";
import { createProPlugin, inputs } from '@formkit/pro'
const pro = createProPlugin('fk-43c2fa9ada', inputs)
const base = {
  outer: "mb-5",
  label: "block text-sm font-semibold leading-6 text-slate-600",
  inner: "mt-1",
  input:
    "appearance-none block w-full px-2 md:px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm formkit-invalid:border-red-500",
  help: "text-sm text-gray-400 mt-1",
  messages: "list-none p-0 mt-1 mb-0 text-sm text-red-600",
  message: "mt-2 text-sm text-red-600",
};
export default {
  plugins: [pro],
  config: {
    classes: generateClasses({
      text: {
        ...base,
      },
      number: {
        ...base,
      },
      password: {
        ...base,
      },
      email: {
        ...base,
      },
      submit: {
        ...base,
        input:
          "w-full flex justify-center py-2 px-4 mt-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 formkit-disabled:bg-gray-400",
      },
      button: {
        input:
          "w-full flex justify-center py-2 mt-6 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 formkit-disabled:bg-gray-400",
      },
      radio: {
        ...base,
        wrapper: "flex items-center",
        fieldset: "mt-4 space-y-4",
        input: "focus:ring-teal-500 h-4 w-4 text-teal-600 border-gray-300",
        label: "ml-3 mt-2 block text-sm font-medium text-gray-700",
        options: "space-y-4",
        legend: "block text-sm font-medium text-gray-700",
      },
      checkbox: {
        ...base,
        wrapper: "relative flex items-start",
        inner: "flex items-center h-5",
        label: `${base.label} ml-3`,
        help: `${base.help} ml-7 mt-0`,
        input:
          "focus:ring-teal-500 h-4 w-4 mt-1 text-teal-600 border-gray-300 rounded",
      },
      textarea: {
        ...base,
        wrapper: "w-full",
        inner: "mt-1 w-full",
        help: "text-sm text-gray-400 mt-1",
        input:
          "shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md",
      },
      select: {
        ...base,
      },
      repeater: {
        ...base,
        item: "flex space-x-4",
        items: 'flex flex-col space-y-4',
        content: "flex-1",
      }
    }),
  },
};
