<script setup>
import { computed } from "vue";
const props = defineProps({
  id: {
    type: String,
    required: false,
    default: "",
  },
  class: {
    type: String,
    required: false,
    default: "",
  },
});

const classes = computed(() => {
  return ['text-gray-500 font-medium capitalize m-0 p-0', props.class];
});
</script>
<template>
  <h5 :id="props.id" :class="classes">
    <slot />
  </h5>
</template>
