<script setup>
const props = defineProps({
  id: {
    type: String,
    required: false,
    default: "",
  },
});
</script>
<template>
  <h3 :id="props.id" class="m-0 text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize">
    <slot />
  </h3>
</template>
