import { watch } from "vue";
import { useLDClient } from "launchdarkly-vue-client-sdk";

import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/AuthStore";


export default function useLaunchDarkly() {
  const authStore = useAuthStore();
  const { currentUser } = storeToRefs(authStore);
  const ldClient = useLDClient();

  function indentifyToLaunchDarkly() {
    if (!currentUser.value) return;

    const ldContext = {
      kind: 'user',
      key: currentUser.value.id,
      name: currentUser.value.firstName + " " + currentUser.value.lastName,
      email: currentUser.value.email,
      role: currentUser.value.role,
    }

    console.log('[useLaunchDarkly] Sending Context to LaunchDarkly')
    ldClient.identify(ldContext)
  }

  watch(currentUser, () => {
    indentifyToLaunchDarkly()
  });

  return {
    ldClient
  };
}


