import http from "@/services/http";

export const UserNotificationService = {
  fetchAll: () => {
    return http.get('/user_notifications');
  },
  markSeen: (id) => {
    return http.put(`/user_notifications/${id}/mark_seen`);
  }
}
