<script setup>
  import { defineProps } from "vue";
  import { ChevronRightIcon } from "@heroicons/vue/24/solid";
  import Heading1 from "@/components/ui/Headings/Heading1.vue";
  const props = defineProps({
    breadCrumbs: {
      type: Object,
      required: false,
      default: null,
    },
    pageTitle: {
      type: String,
      required: false,
      default: () => null,
    },
  });


</script>

<template>
  <div
    id="page-header"
    class="lg:flex lg:items-center lg:justify-between pt-4 mb-2 md:mb-4 mt-0 md:mt-2 xs:mt-0"
  >
    <div class="flex-1 min-w-0">
      <nav
        v-if="props.breadCrumbs"
        class="flex"
        aria-label="Breadcrumb"
      >
        <ol
          role="list"
          class="flex items-center space-x-1"
        >
          <li
            v-for="(crumb, index) in props.breadCrumbs"
            :key="crumb.label"
          >
            <div class="flex items-center">
              <ChevronRightIcon
                v-if="index !== 0"
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <router-link
                :to="crumb.to"
                class="text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {{ crumb.label }}
              </router-link>
            </div>
          </li>
        </ol>
      </nav>
      <Heading1 class="text-teal-600">
        {{ props.pageTitle }}
        <slot name="title" />
      </Heading1>
      <div
        v-if="$slots.details"
        class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
      >
        <slot name="details" />
      </div>
    </div>
    <div
      v-if="$slots.buttons"
      id="page-header--buttons"
      class="mt-5 flex lg:mt-0 lg:ml-4 justify-end"
    >
      <slot name="buttons" />
    </div>
  </div>
</template>
