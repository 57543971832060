import { CapacitorCookies , Capacitor } from "@capacitor/core";
import { Preferences } from '@capacitor/preferences';
import decode from "jwt-decode";
import axios from "axios";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export const JwtService = {
  async setCookie(jwt) {
    await CapacitorCookies.setCookie({
      key: "akt-jwt",
      value: jwt,
      expires: new Date(decode(jwt).exp * 1000).toUTCString(),
    });
  },

  async destroyCookie() {
    await CapacitorCookies.deleteCookie({
      key: "akt-jwt",
    });
    axios.defaults.headers.common["Authorization"] = null;
  },

  currentJwt() {
    const jwt = cookies.get("akt-jwt");
    return jwt ? jwt : null;
  },

  decodedJwt() {
    const jwt = this.currentJwt();
    return jwt ? decode(jwt) : null;
  },

  currentJwtUser() {
    const jwt = this.decodedJwt();
    return jwt ? jwt.user : null;
  },

  isExpired() {
    const jwt = this.decodedJwt();
    if (!jwt) return true;
    if (jwt.exp * 1000 < Date.now()) {
      console.log("jwt expired");
    } else {
      return false;
    }
  },

  async setCredential(email, password){
    const platform = Capacitor.getPlatform();
    if(platform === 'ios' || platform === 'android') {
      await Preferences.set({
        key: 'email',
        value: email,
      });
      await Preferences.set({
        key: 'password',
        value: password,
      });
    }
  },

  async getCredential() {
    try{
      const { value: email } = await Preferences.get({ key: 'email' });
      const { value: password } = await Preferences.get({ key: 'password' });
      return {
        email: email || '', 
        password: password || ''
      }
    }catch(err){
      return {
        email: '', 
        password: ''
      }
    }
  }
};
