<script setup>
import { storeToRefs } from "pinia";
import UserAvatar from "@/components/Atoms/UserAvatar.vue";
import MainNav from "@/layouts/MainNav";
import { useAuthStore } from "@/stores";

const authStore = useAuthStore();
const { currentUser } = storeToRefs(authStore);
</script>

<template>
  <div
    v-if="currentUser"
    class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0"
  >
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex-1 flex flex-col min-h-0 bg-gray-800">
      <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4">
          <h1 class="font-sans text-white text-2xl font-black">
            <span class="text-teal-400">AK</span>
            <span class="font-extralight">FITNESS</span>
          </h1>
        </div>
        <MainNav />
      </div>
      <div v-if="currentUser" class="flex-shrink-0 flex bg-gray-700 p-4">
        <a href="#" class="flex-shrink-0 w-full group block">
          <div class="flex items-center">
            <UserAvatar :user="currentUser" />
            <div class="ml-3">
              <p class="text-sm font-medium text-white">
                {{ currentUser.fullName }}
              </p>
              <router-Link
                to="/my_profile"
                class="text-xs font-medium text-gray-300 group-hover:text-gray-200"
              >
                View profile
              </router-Link>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
