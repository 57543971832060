import { defineStore } from "pinia";

export const useSearchStore = defineStore("searchStore", {
  state: () => {
    return {
      recentSearches: [],
      dialogOpen: false,
    };
  },
  actions: {
    async toggleDialog() {
      this.dialogOpen = !this.dialogOpen;
    },
  },
});
