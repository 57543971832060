import { useAuthStore } from "@/stores";
export default [
  {
    path: "/training_programs",
    name: "TrainingPrograms",
    component: function () {
      const authStore = useAuthStore();
      if (authStore.isTrainer) {
        return import("@/pages/TrainingPrograms/ListTrainingProgramsPage.vue");
      } else {
        return import("@/pages/Clients/ClientTrainingPrograms.vue");
      }
    },
  },
  {
    path: "/training_programs/:trainingProgramId/edit",
    name: "TrainingPrograms#Edit",
    component: () => import("@/pages/TrainingPrograms/EditTrainingProgramPage.vue"),
    meta: {
      title: "Edit Program",
    },
  },
]
