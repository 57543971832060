<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    title: {
      type: String,
      default: "Nothing to see here...",
    },
  });
</script>

<template>
  <div
    class="block mt-5 p-5 text-center border bg-white border-gray-300 border-dashed rounded-lg"
  >
    <span class="text-xl text-teal-500 font-medium mb-5">
      {{ props.title }}
    </span>
    <p class="mt-1 text-sm text-gray-500">
      <slot />
    </p>
  </div>
</template>
