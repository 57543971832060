<script setup>
  import AtomStatWithIcon from "@/components/Atoms/AtomStatWithIcon.vue";
  import AtomStat from "../Atoms/AtomStat.vue";
  import Panel from "@/components/ui/Panel";
  import { Heading3, Heading5 } from "@/components/ui/Headings";

  const props = defineProps({
    goals: {
      type: Object,
      default: () => {
        return {};
      },
    },
    previousGoals: {
      type: Object,
      default: () => {
        return {};
      },
    },
  });

</script>

<template>
  <div>
    <Heading3 class="py-4">
      Macro Goals
    </Heading3>
    <div class="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-4 gap-5 sm:gap-6">
      <AtomStatWithIcon
        label="Protein"
        :stat-value="props.goals.dailyMacros.protein"
        :previous-stat-value="props.previousGoals?.dailyMacros?.protein || null"
        stat-unit="g"
        bg-color="bg-rose-500"
        icon="fa-fish-fins"
      />
      <AtomStatWithIcon
        label="Carbs"
        :stat-value="props.goals.dailyMacros.carbs"
        :previous-stat-value="props.previousGoals?.dailyMacros?.carbs || null"
        stat-unit="g"
        bg-color="bg-yellow-500"
        icon="fa-wheat-awn"
      />
      <AtomStatWithIcon
        label="Fat"
        :stat-value="props.goals.dailyMacros.fat"
        :previous-stat-value="props.previousGoals?.dailyMacros?.fat || null"
        stat-unit="g"
        bg-color="bg-orange-500"
        icon="fa-egg"
      />
      <AtomStatWithIcon
        label="Water"
        :stat-value="props.goals.dailyMacros.water"
        :previous-stat-value="props.previousGoals?.dailyMacros?.water || null"
        stat-unit="oz"
        bg-color="bg-indigo-500"
        icon="fa-glass-water-droplet"
      />
    </div>
    <div>
      <Heading3 class="py-4">
        Nutrition Goals
      </Heading3>
      <div class="grid lg:grid-cols-3 grid-cols-1 gap-4">
        <AtomStat
          label="Daily Average Sodium"
          :current-stat="props.goals.nutrition.dailyAverageSodium"
          :previous-stat="props.previousGoals?.nutrition?.dailyAverageSodium"
          unit="mg"
        />
        <AtomStat
          label="Daily Average Fiber"
          :current-stat="props.goals.nutrition.dailyAverageFiber"
          :previous-stat="props.previousGoals?.nutrition?.dailyAverageFiber"
          unit="g"
        />
        <AtomStat
          label="Calories"
          :current-stat="props.goals.nutrition.dailyCalories"
          :previous-stat="props.previousGoals?.nutrition?.dailyCalories"
        />
      </div>
    </div>
    <div>
      <Heading3 class="py-4">
        Exercise Goals
      </Heading3>
      <div class="grid lg:grid-cols-3 grid-cols-1 gap-4">
        <AtomStat
          label="Cardio Sessions"
          :current-stat="props.goals?.excercise?.cardioSessions"
          :previous-stat="props.previousGoals?.excercise?.cardioSessions"
        />
        <AtomStat
          label="Total Cardio Minutes"
          :current-stat="props.goals?.excercise?.totalCardioMinutes"
          :previous-stat="props.previousGoals?.excercise?.totalCardioMinutes"
          unit="min"
        />
        <AtomStat
          label="Daily Average Step Count"
          :current-stat="props.goals?.excercise?.dailyAverageStepCount"
          :previous-stat="props.previousGoals?.excercise?.dailyAverageStepCount"
          unit=" steps"
        />
      </div>
      <Panel class="mt-4">
        <Heading5>Instructions from your Coach</Heading5>
        {{ props.goals.coachExerciseNotes || "No additional notes" }}
      </Panel>
    </div>
  </div>
</template>
@/components/ui/Headings
