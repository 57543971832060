import ListConversationsPage from "@/pages/Conversations/ListConversationsPage.vue";
import DoConversationPage from "@/pages/Conversations/DoConversationPage.vue";
export default [
  {
    path: "/conversations",
    name: "Inbox",
    component: ListConversationsPage,
  },
  {
    path: "/conversations/:conversationId",
    name: "ConversationView",
    component: DoConversationPage
  },
];
