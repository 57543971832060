import http from "@/services/http";

export const MessageService = {
  fetchAllForConversation: ({ conversationId }) => {
    return http.get(`/conversations/${conversationId}/messages`);
  },
  fetchOneByConversation: ({ conversationId, messageId }) => {
    return http.get(`/conversations/${conversationId}/messages/${messageId}`);
  },
  create: ({ conversationId, message }) => {
    return http.post(`/conversations/${conversationId}/messages`, message);
  },
  fetchUnread: () => {
    return http.get(`/conversations/unread`);
  },
  markAsSeen: ({ conversationId, messageId }) => {
    return http.post(
      `/conversations/${conversationId}/messages/${messageId}/mark_seen`
    );
  },
};
