export default [
  {
    path: "/training_programs/:trainingProgramId/check_in",
    name: "TrainingPrograms#CheckIn",
    props: true,
    component: () => import("@/pages/CheckIns/DoCheckInPage.vue"),
    meta: {
      title: "Check In",
    },
  },
  {
    path: "/clients/:id/check_ins",
    name: "ViewClientCheckInHistory",
    component: () => import("@/pages/CheckIns/ClientCheckInHistory.vue"),
    meta: {
      requireCoach: true,
    },
  },
  {
    path: "/check_ins/:id/review",
    name: "ReviewCheckIn",
    component: () => import("@/pages/CheckIns/DoCheckInReviewPage.vue"),
    meta: {
      requireCoach: true,
    },
  },

]
