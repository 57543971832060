export const LOG_EMOJIS = {
  ROUTER: "🔀",
  WORKOUTS: "🏋️",
  CHECK_INS: "📋",
};

function createLogger(context, emoji) {
  return function (...args) {
    console.log(emoji, `[${context.replace('_',' ')}]`, ...args);
  };
}

export const routerLogger = createLogger("ROUTER", LOG_EMOJIS.ROUTER);
