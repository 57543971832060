import http from "@/services/http";

export const TrainingProgramService = {
  create: (trainerId, trainingProgram) => {
    return http.post(`/trainers/${trainerId}/training_programs`, {
      training_program: trainingProgram,
    });
  },
  findAllByTrainer: (trainerId) => {
    return http.get(`/trainers/${trainerId}/training_programs`);
  },
  findOneByTrainer: ({ trainerId, trainingProgramId }) => {
    return http.get(
      `/trainers/${trainerId}/training_programs/${trainingProgramId}`
    );
  },
  update: (trainingProgram) => {
    let payload = {
      trainer_id: trainingProgram.trainerId,
      title: trainingProgram.title,
      check_in_template_id: trainingProgram.checkInTemplateId,
      clients: trainingProgram.clients,
    };
    return http.patch(
      `/trainers/${trainingProgram.trainerId}/training_programs/${trainingProgram.id}`,
      { training_program: payload }
    );
  },
  makeDefault: () => {
    console.log("implement me");
    return true;
  },
};

export default TrainingProgramService;
