export const GET_DRAFTS_PATH = () => "/check_ins?state=draft"

export const GET_CHECK_INS_WAITING_FOR_REVIEW_PATH = () => '/check_ins?state=pending_review'

export const GET_DRAFT_BY_TRAINING_PROGRAM_PATH = (trainingProgramId) =>
  `/check_ins?state=draft&training_program_id=${trainingProgramId}`

export const CREATE_CHECK_IN_PATH = (trainerId, trainingProgramId) =>
  `/trainers/${trainerId}/training_programs/${trainingProgramId}/check_ins`

export const FIND_OR_CREATE_DRAFT_CHECK_IN_PATH = (trainerId, trainingProgramId) =>
  `/trainers/${trainerId}/training_programs/${trainingProgramId}/check_ins/draft`

export default {
  GET_DRAFTS_PATH,
  GET_DRAFT_BY_TRAINING_PROGRAM_PATH,
  FIND_OR_CREATE_DRAFT_CHECK_IN_PATH,
  CREATE_CHECK_IN_PATH
}

