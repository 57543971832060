export default [
  {
    path: "/check_in_templates",
    name: "MyTemplates",
    component: () => import("@/pages/CheckInTemplates/ListCheckInTemplatesPage.vue"),
    meta: {
      requireCoach: true
    },
  },
  {
    path: "/check_in_templates/:id/edit",
    name: "templates/edit",
    component: () => import("@/pages/CheckInTemplates/EditCheckInTemplatePage.vue"),
    meta: {
      title: "Edit Template",
    },
  },
]
