<script setup>
import AtomStatDifferenceIndicator from "./AtomStatDifferenceIndicator.vue";
const props = defineProps({
  currentStat: {
    type: Number,
    default: 0,
  },
  previousStat: {
    type: Number,
    default: null,
  },
  label: {
    type: String,
    default: "",
  },
  unit: {
    type: String,
    default: "",
  },
});
</script>

<template>
  <div class="flex px-2 py-3 bg-white shadow rounded-md border overflow-hidden sm:p-3">
    <div class="flex-1">
      <p class="text-gray-500 font-medium capitalize text-sm">
        {{ props.label }}
      </p>
      <p class="text-3xl font-semibold text-gray-900">
        {{ props.currentStat }}<span class="text-xl"> {{ props.unit }}</span>
      </p>
    </div>
    <AtomStatDifferenceIndicator
      v-if="props.previousStat"
      :current-stat="props.currentStat"
      :previous-stat="props.previousStat"
    />
  </div>
</template>
