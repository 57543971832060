// Client routes are not routes for Client roles - but routes for the Client resource belonging to the Coach role.
import InviteClientPage from "@/pages/Clients/InviteClientPage.vue"
import ListClientsPage from "@/pages/Clients/ListClientsPage.vue"
import ViewClientPage from "@/pages/Clients/ViewClientPage.vue"
import EditClientPage from "@/pages/Clients/EditClientPage.vue"

export default [
  {
    path: "/clients",
    name: "ActiveClientsPage",
    component: ListClientsPage,
    meta: {
      requireCoach: true
    },
  },
  {
    path: "/clients/archived",
    name: "ArchivedClientsPage",
    component: ListClientsPage,
    meta: {
      requireCoach: true,
      showArchived: true,
    },
  },
  {
    path: "/clients/invite",
    name: "InviteClientPage",
    component: InviteClientPage,
    meta: {
      title: "Invite Client",
    },
  },
  {
    path: "/clients/:id",
    name: "ViewClientPage",
    component: ViewClientPage,
    meta: {
      requireCoach: true
    },
  },
  {
    path: "/clients/:id/edit",
    name: "EditClientPage",
    component: EditClientPage,
    meta: {
      requireCoach: true
    },
  },
  {
    path: "/clients/:id/goals/edit",
    name: "EditClientGoals",
    component: () => import("@/pages/Clients/EditClientGoals.vue"),
    meta: {
      requireCoach: true
    },
  },

]
