
import checkInRoutes from "./checkInRoutes";
import checkInTemplateRoutes from "./checkInTemplateRoutes";
import clientRoutes from "./clientRoutes";
import conversationRoutes from "./conversationRoutes";
import publicRoutes from "./publicRoutes";
import sharedRoutes from "./sharedRoutes";
import trainingProgramRoutes from "./trainingProgramRoutes";
import workoutTemplateRoutes from "./workoutTemplateRoutes";
import workoutRoutes from "./workoutRoutes";

export default [
  ...checkInTemplateRoutes,
  ...publicRoutes,
  ...workoutTemplateRoutes,
  ...workoutRoutes,
  ...sharedRoutes,
  ...clientRoutes,
  ...conversationRoutes,
  ...trainingProgramRoutes,
  ...checkInRoutes,
];
