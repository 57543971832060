<template>
  <Line
    :data="chartData"
    :options="options"
  />
</template>

<script setup>
  import { defineProps, computed, ref, onMounted } from "vue";
  import { getDate } from "@/utils/formatters";
  import { Line } from "vue-chartjs";
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";

  const props = defineProps({
    checkIns: {
      type: Array,
      default: () => {
        return [];
      },
    },
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const lineColor = "rgb(97, 206, 192)";
  const labels = ref([]);
  const data = ref([]);

  onMounted(() => {
    labels.value = props.checkIns.map((checkIn) => getDate(checkIn.createdAt));
    data.value = props.checkIns.map((checkIn) => checkIn.measurements.weight);
  });

  const chartData = computed(() => {
    return {
      labels: labels.value,
      datasets: [
        {
          backgroundColor: "#6466f1",
          data: data.value,
          tension: 0.1,
          pointBorderColor: lineColor,
          pointBackgroundColor: lineColor,
          borderColor: lineColor,
        },
      ],
    };
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
</script>
