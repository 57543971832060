import http from "@/services/http";

export const ClientGoalsService = {
  create: ({ clientId, data }) => {
    return http.post(`/clients/${clientId}/goals`, data);
  },
  fetchOneByClient: ({ clientId, goalId }) => {
    return http.get(`/clients/${clientId}/goals/${goalId}`);
  },
  fetchAllByClient: ( clientId ) => {
    return http.get(`/clients/${clientId}/goals`);
  },
};

export default ClientGoalsService;
