import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { CheckInService } from "@/services";

export function useCheckInsPendingReviewQuery() {
  const queryKey = ['checkIns', 'pendingReview'];
  const queryClient = useQueryClient();

  return useQuery(
    queryKey, () => CheckInService.fetchAllUnReviewed().then(res => res.data),
    {
      initialData: queryClient.getQueryData(queryKey)?.data || [],
    }
  )
}
