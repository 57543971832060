import { defineStore } from "pinia";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/vue";
import { AuthService, JwtService, UserService } from "@/services";

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      currentUser: null,
      returnUrl: null,
    };
  },
  actions: {
    async hydrateUserFromJwt() {
      console.log("hydrateUserFromJwt");
      let user = JwtService.currentJwtUser();
      if (user.id) {
        await UserService.fetchOne(user.id)
          .then((res) => {
            this.setCookieAndAxiosState(res.data);
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.logout();
              throw err;
            }
          });
      }
    },
    async setCookieAndAxiosState(user) {
      if (!user) {
        return;
      }
      this.currentUser = user;
      await JwtService.setCookie(user.accessToken);

      if (process.env.NODE_ENV === "production") {
        LogRocket.identify(user.id, {
          name: user.firstName + " " + user.lastName,
          email: user.email,
        });
        Sentry.setUser({
          id: user.id,
          username: user.email,
          email: user.email,
          first_name: user.firstName,
          last_name: user.lastName,
          role: user.role,
          segment: user.role,
        });
      }
    },
    async register(user) {
      return new Promise((resolve, reject) => {
        UserService.create({ ...user, email: user.email.toLowerCase() })
          .then(({ data: user }) => {
            this.setCookieAndAxiosState(user);
            resolve(user);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    login(user) {
      this.currentUser = user;
    },
    logout() {
      console.log("loggin out");
      return new Promise((resolve, reject) => {
        AuthService.logout()
          .then((res) => {
            JwtService.destroyCookie();
            this.currentUser = null;
            this.setCookieAndAxiosState(null);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    jwtUser: () => {
      return JwtService.currentJwtUser();
    },
    jwt: () => {
      return JwtService.currentJwt();
    },
    isLoggedIn: function (state) {
      return state.currentUser && !JwtService.isExpired();
    },
    isClient: (state) =>
      state.currentUser && state.currentUser.role == "client",
    isTrainer: (state) =>
      state.currentUser && state.currentUser.role == "trainer",
  },
});
