import http from "@/services/http";

export const ProgressPhotoService = {
  create: ({ checkInId, photo }) => {
    const formData = new FormData();
    formData.append("photo", photo.photo);
    formData.append("angle", photo.angle);
    return http.post(`/check_ins/${checkInId}/progress_photos`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  findOne: ({ checkInId, id }) => {
    return http.get(`/check_ins/${checkInId}/progress_photos/${id}`);
  },
};
