<script setup>
import {computed} from "vue";
const props = defineProps({
  id: {
    type: String,
    required: false,
    default: "",
  },
  class: {
    type: String,
    required: false,
    default: "",
  },
});

const classes = computed(() => {
  return ['m-0 text-lg leading-6 font-semibold tracking-tight text-gray-900 capitalize', props.class];
});
</script>
<template>
  <h4 :id="props.id" :class="classes">
    <slot />
  </h4>
</template>
