<script setup>
import { ref, onBeforeMount } from "vue";
import ConversationListItem from "@/components/ConversationListItem.vue";
import PageHeader from "@/components/PageElements/PageHeader";
import { ConversationService } from "@/services";

const conversations = ref([]);

onBeforeMount(() => {
  ConversationService.fetchAllForUser()
    .then(({ data }) => {
      conversations.value = data.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
    })
    .catch((error) => {
      console.log(error);
    });
});
</script>

<template>
  <div>
    <PageHeader page-title="Conversations">
    </PageHeader>
    <div
      v-if="conversations.length > 0"
      class="bg-white shadow overflow-hidden sm:rounded-md"
    >
      <ul role="list" class="divide-y divide-gray-200">
        <ConversationListItem
          v-for="conversation in conversations"
          :key="conversation.id"
          :conversation="conversation"
        />
      </ul>
    </div>
  </div>
</template>
