import http from "@/services/http";
import {
  FIND_OR_CREATE_DRAFT_CHECK_IN_PATH,
  GET_DRAFTS_PATH,
  GET_DRAFT_BY_TRAINING_PROGRAM_PATH,
  CREATE_CHECK_IN_PATH,

} from "@/services/paths/CheckInApiPaths";

function normalizeKeys(obj) {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(normalizeKeys);
    } else {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
          const newKey = key.startsWith('answer_') ? 'answer' : key;
          return [newKey, normalizeKeys(value)];
        })
      );
    }
  }
  return obj;
}

export const CheckInService = {
  create: (trainerId, trainingProgramId, payload) => {
    return http.post(
      CREATE_CHECK_IN_PATH(trainerId, trainingProgramId),
      payload
    );
  },

  update: ({ checkInId, payload }) => {
    return http.put(`check_ins/${checkInId}?`, normalizeKeys(payload));
  },

  submit: ({ checkInId, data }) => {
    return http.put(`check_ins/${checkInId}?submitting=true`, normalizeKeys(data));
  },

  review: ({ checkInId, data }) => {
    return http.patch(`check_ins/${checkInId}?reviewing=true`, data);
  },

  fetchClientDrafts: () => {
    return http.get(GET_DRAFTS_PATH());
  },

  fetchDraftForTrainingProgram: (trainingProgramId) => {
    return http.get(
      GET_DRAFT_BY_TRAINING_PROGRAM_PATH(trainingProgramId),
    );
  },

  findOrCreateDraftForTrainingProgram: (trainerId, trainingProgramId) => {
    try {
      return http.post(
        FIND_OR_CREATE_DRAFT_CHECK_IN_PATH(trainerId, trainingProgramId),
      );
    } catch (error) {
      console.error("Failed to find or create draft check-in:", error);
      throw error;
    }
  },

  fetchAllUnReviewed: () => {
    return http.get('/check_ins?state=pending_review')
  },

  fetchAllReviewed: () => {
    return http.get(`/check_ins?state=reviewed`);
  },

  findOne: (id) => {
    return http.get(`/check_ins/${id}`);
  },

  findAllByClient: (clientId) => {
    return http.get(`/check_ins?client_id=${clientId}`);
  },

  findAllReviewedByClient: (clientId) => {
    return http.get(`/check_ins?client_id=${clientId}&state=reviewed`);
  },

  findAll: () => {
    return http.get(`/check_ins`);
  },
};

export default CheckInService;
