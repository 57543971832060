// This file contains the routes that are shared between the Client and Coach roles
import { useAuthStore } from "@/stores";

export default [
  {
    path: "/",
    name: "Dashboard",
    component: function () {
      const authStore = useAuthStore();

      console.log(authStore.isTrainer);
      if (authStore.isTrainer) {
        return import("@/pages/Coaches/CoachDashboardPage.vue");
      } else {
        return import("@/pages/Clients/ClientDashboardPage.vue");
      }
    },
  },

  {
    path: "/check_ins",
    name: "CheckIns",
    component: function () {
      const authStore = useAuthStore();
      if (authStore.isTrainer) {
        return import("@/pages/CheckIns/CoachIndexPage.vue");
      } else {
        return import("@/pages/CheckIns/ClientIndexPage.vue");
      }
    },
    meta: {
      title: "Check-Ins",
    },
  },
  {
    path: "/meal_plans",
    name: "MealPlans",
    component: () => import("@/pages/MealPlans.vue"),
  },
  {
    path: "/resources",
    name: "Resources",
    component: () => import("@/pages/ResourcePage.vue"),
    meta: {
      title: "Resources",
    },
  },
  {
    path: "/my_profile",
    name: "MyProfile",
    component: () => import("@/pages/Shared/MyProfile.vue"),
    meta: {
      title: "My Profile",
    },
  },
];
