// Import specific icons
import {
  faUserSecret,
  faFishFins,
  faWheatAwn,
  faEgg,
  faGlassWaterDroplet,
} from '@fortawesome/free-solid-svg-icons'

import {
  faTrash,
  faTrashCanXmark,
  faSpinnerThird,
  faWandMagic,
  faWandMagicSparkles,
  faSalad
} from '@fortawesome/pro-duotone-svg-icons'

export default {
  faUserSecret,
  faFishFins,
  faWheatAwn,
  faEgg,
  faGlassWaterDroplet,
  faTrash,
  faTrashCanXmark,
  faSpinnerThird,
  faWandMagic,
  faWandMagicSparkles,
  faSalad
}
