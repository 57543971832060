<template>
  <component :is="getIconComponent" :class="props.classes" aria-hidden="true"/>
</template>

<script setup>
import { computed } from 'vue';
import {
  BriefcaseIcon,
  CalendarIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  FireIcon,
  LinkIcon,
  MapPinIcon,
  PencilIcon,
  PhoneIcon,
  PlusIcon,
  UsersIcon,
  UserPlusIcon,


} from '@heroicons/vue/20/solid'

const props = defineProps({
  classes: {
    type: String,
    required: false,
    default: "",
  },
  icon: {
    type: String,
    required: true,
  },
});

const getIconComponent = computed(() => {
  switch (props.icon) {
  case 'briefcase':
    return BriefcaseIcon;
  case 'calendar':
    return CalendarIcon;
  case 'chat':
    return ChatBubbleOvalLeftEllipsisIcon;
  case 'check':
    return CheckIcon;
  case 'chevron-down':
    return ChevronDownIcon;
  case 'chevron-right':
    return ChevronRightIcon;
  case 'currency-dollar':
    return CurrencyDollarIcon;
  case 'fire':
    return FireIcon;
  case 'phone':
    return PhoneIcon;
  case 'link':
    return LinkIcon;
  case 'map-pin':
    return MapPinIcon;
  case 'pencil':
    return PencilIcon;
  case 'plus':
    return PlusIcon;
  case 'envelope':
    return EnvelopeIcon;
  case 'users':
    return UsersIcon;
  case 'users-plus':
    return UserPlusIcon;
  default:
    return null;
  }
});

</script>
