import axios from "axios";
import { Capacitor } from "@capacitor/core";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Client": Capacitor.isNativePlatform() ? "mobile" : "web",
    "X-Client-Version": process.env.VUE_APP_VERSION,
  },
});

export const AuthService = {
  login: ({ email, password }) => {
    const token = localStorage.getItem("push_notification_token");
    return new Promise((resolve, reject) => {
      http
        .post("/api/v1/authenticate/login", {
          email: email,
          password: password,
          deviceToken: token,
          platform: Capacitor.getPlatform(),
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log("error = ", JSON.stringify(error));
          reject(error);
        });
    });
  },
  logout: () => {
    return new Promise((resolve, reject) => {
      http
        .delete("/api/v1/authenticate/logout")
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  forgotPassword: ({ email }) => {
    return new Promise((resolve, reject) => {
      http
        .post("/api/v1/passwords/forgot", { email: email })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  resetPassword: ({ token, password }) => {
    return new Promise((resolve, reject) => {
      http
        .post("/api/v1/passwords/reset", {
          token: token,
          password: password,
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
};
