<script setup>
  import Swal from "sweetalert2";

  import MessageUserButton from "@/components/Atoms/MessageUserButton.vue";
  import PageHeaderElement from "@/components/ui/PageHeaderElement.vue";
  import TabLinksElement from "@/components/ui/TabLinksElement.vue";
  import UserAvatar from "@/components/Atoms/UserAvatar.vue";
  import { useRouter } from "vue-router";

  import ClientService from "@/services/ClientService";

  import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
  import { ChevronDownIcon } from "@heroicons/vue/24/solid";

  const props = defineProps({
    client: {
      type: Object,
      required:true
    }
  })
  const router = useRouter();
  const handleArchiveClick = async () => {
    const result = await Swal.fire({
      title: "Archive Client?",
      text: "They will not be able to log in to the app, but you can still view their data.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, archive them!",
    });

    if (result.isConfirmed) {
      try {
        await ClientService.archiveOne(props.client.id);
        await Swal.fire("Archived!", "Client has been archived.", "success");
        router.push({ name: "ActiveClientsPage" });
      } catch (error) {
        console.error("An error occurred while archiving the client:", error);
        Swal.fire("Error", "There was an issue archiving the client.", "error");
      }
    }
  };


  const handleUnArchiveClick = () => {
    Swal.fire({
      title: "Restore Client?",
      text: "They will be able to log in to the app again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, restore them!",
    }).then((result) => {
      if (result.isConfirmed) {
        ClientService.unArchiveOne(props.client.id).then(() => {
          Swal.fire("Success!", "Client has been restored.", "success").then(
            () => {
              router.push({ name: "ActiveClientsPage" });
            }
          );
        });
      }
    });
  };

  const handleDeleteClick = () => {
    Swal.fire({
      icon: "warning",
      title: "Please confirm",
      html: `To delete this Client, please type their email address <strong>${props.client.email}</strong> in the box below and click delete. </br></br> This action cannot be undone.`,
      input: "email",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Delete",
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        if (email != props.client.email) {
          Swal.showValidationMessage(
            `The email address you entered does not match the email address for this client.`
          );
          return;
        }
        return ClientService.delete(props.client.id)
          .then((response) => {
            if (response.status != 200) {
              throw new Error(response.statusText);
            }
            router.push({ name: "ActiveClientsPage" });
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: `Client deleted`,
        });
      }
    });
  };
  const tabs = [
    { label: "Dashboard", route: { name: "ViewClientPage", params: { id: props.client.id } }, iconClass: "fa fa-gauge-high" },
    { label: "Workouts", route: { name: "Workouts#Coach#ListClientWorkoutsPage", params: { id: props.client.id } }, iconClass: "fa fa-dumbbell" },
    { label: "Workout Templates", route: { name: "WorkoutTemplates:Coach:ListClientWorkoutTemplatesPage", params: { id: props.client.id } }, iconClass: "fa fa-weight-hanging" },
    { label: "Check-Ins", route: { name: "ViewClientCheckInHistory", params: { id: props.client.id } }, iconClass: "fa fa-clipboard-check" },
  ];
</script>

<template>
  <div v-if="client">
    <PageHeaderElement>
      <UserAvatar
        :user="client"
        size="8"
        class-names="mr-2"
      />
      {{ client.fullName }}
      <div class="flex flex-col sm:flex-row ml-1 mt-2 text-base text-slate-700">
        <span class="mr-2">
          <i class="fa-duotone fa-fire mr-2 text-lg" />
          <a
            :href="`mailto:${client.email}`"
            class="text-teal-600 font-semibold"
          >
            {{ (client.trainingPrograms.length > 0 && client.trainingPrograms[0].title) || 'Unassigned' }}
          </a>
        </span>
        <span class="mr-2">
          <i class="fa-duotone fa-circle-envelope mr-2 text-lg" />
          <a
            :href="`mailto:${client.email}`"
            class="text-teal-600 font-semibold"
          >
            {{ client.email }}
          </a>
        </span>

        <span class="mr-2 text-slate-700">
          <i class="fa-duotone fa-circle-phone mr-2 text-lg" />
          <a
            :href="`tel:${client.phoneNumber}`"
            class="text-teal-600 font-semibold"
          >
            {{ client.phoneNumber || 'Unknown' }}
          </a>
        </span>
      </div>
      <template #header-actions>
        <MessageUserButton
          :user-id="client.id"
          class="mr-3"
        />
        <Menu
          as="div"
          class="relative inline-block text-left z-50"
        >
          <MenuButton
            class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          >
            Options
            <ChevronDownIcon
              class="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <router-link
                    :to="{
                      name: 'EditClientPage',
                      params: { id: client.id },
                    }"
                    :class="[
                      active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                      'block w-full text-left px-4 py-2 text-sm',
                    ]"
                  >
                    Edit
                  </router-link>
                </MenuItem>
                <MenuItem
                  v-if="client.active"
                  v-slot="{ active }"
                >
                  <button
                    :class="[
                      active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                      'block w-full text-left px-4 py-2 text-sm',
                    ]"
                    @click="handleArchiveClick"
                  >
                    Archive
                  </button>
                </MenuItem>
                <MenuItem
                  v-if="!client.active"
                  v-slot="{ active }"
                >
                  <button
                    :class="[
                      active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                      'block w-full text-left px-4 py-2 text-sm',
                    ]"
                    @click="handleUnArchiveClick"
                  >
                    Unarchive
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button
                    :class="[
                      active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                      'block w-full text-left px-4 py-2 text-sm',
                    ]"
                    @click="handleDeleteClick"
                  >
                    Delete
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </template>
    </PageHeaderElement>
    <TabLinksElement :tabs="tabs" />
  </div>
</template>
