<script setup>
import {
  ref,
  onBeforeMount,
  onMounted,
  computed,
  onBeforeUnmount,
  watch,
  nextTick,
} from "vue";
import { useRoute } from "vue-router";
import { createConsumer } from "@rails/actioncable";
import UserAvatar from "@/components/Atoms/UserAvatar.vue";
import EmptyState from "@/components/ui/EmptyState.vue";
import ChatMessage from "@/components/ChatMessage";
import ChatForm from "@/components/ChatForm";
import PageHeader from "@/components/PageElements/PageHeader";
import { useCurrentUser } from "@/composables/useCurrentUser";
import { ConversationService, JwtService, MessageService } from "@/services";

const cable = ref(null);
const channel = ref(null);
const messages = ref([]);
const conversation = ref(null);
const bottomOfChat = ref(null);
const route = useRoute();
const currentUser = useCurrentUser();
const actionCableUrl =
  process.env.VUE_APP_API_BASE_URL.replace("http://", "ws://") +
  "/cable?jwt=" +
  JwtService.currentJwt();

function connectToChat() {
  console.log("[CHAT] Connecting...");
  cable.value = createConsumer(actionCableUrl);
  channel.value = cable.value.subscriptions.create(
    {
      channel: "ConversationChannel",
      conversation: route.params.conversationId,
    },
    {
      connected: function () {
        console.log("[CHAT] Connected");
      },
      disconnected: function (error) {
        console.log("[CHAT] Disconnected: ", error);
      },
      error: function (error) {
        console.log("[CHAT] Error: ", error);
      },
      received: (message) => {
        console.log("[CHAT] Message received");
        messages.value.push(JSON.parse(message));
      },
      rejected: (message) => {
        console.log("[CHAT] Rejected", message);
      },
    }
  );
}

onMounted(() => {
  connectToChat();
});

onBeforeMount(() => {
  fetchAllMessages();
  fetchConversation();
});

onBeforeUnmount(() => {
  channel.value.unsubscribe();
  cable.value.disconnect();
});

function fetchConversation() {
  ConversationService.fetchById({
    conversationId: route.params.conversationId,
  })
    .then((res) => {
      conversation.value = res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

function fetchAllMessages() {
  MessageService.fetchAllForConversation({
    conversationId: route.params.conversationId,
  })
    .then(({ data }) => {
      messages.value = data.reverse();
    })
    .catch((error) => {
      console.log(error);
    });
}

watch(
  messages,
  (_newMessages, _oldMessages) => {
    nextTick(() => {
      scrollChatWindow();
    });
  },
  { deep: true }
);

const filteredMessages = computed(() => {
  return messages.value.map((message, index) => {
    return {
      ...message,
      previousTimestamp: messages.value[index - 1]
        ? messages.value[index - 1].createdAt
        : null,
      isLastMessage: index === messages.value.length - 1,
    };
  });
});

function scrollChatWindow() {
  bottomOfChat.value?.scrollIntoView({ behavior: "smooth" });
}

const members = computed(() => {
  return conversation.value.users.map((member) => {
    return {
      ...member,
      isCurrenUser: member.id === currentUser.value.id,
    };
  });
});

const membersNotCurrentUser = computed(() => {
  return members.value.filter((member) => {
    return member.id !== currentUser.value.id;
  });
});

const memberNames = computed(() => {
  return membersNotCurrentUser.value
    .map((member) => member.fullName)
    .join(", ");
});
</script>

<template>
  <div class="flex flex-col flex-auto justify-center overflow-hidden">
    <PageHeader
      v-if="conversation"
      class="fixed top-[60px] md:top-0 md:mt-0 md:left-[256px] p-4 left-0 right-0 z-40 shadow bg-white"
    >
      <template #title>
        <div class="flex -space-x-3 overflow-hidden items-center">
          <UserAvatar
            v-for="member in membersNotCurrentUser"
            :key="member.id"
            :user="member"
            size="10"
          />
          <div class="relative left-5 text-base">
            {{ memberNames }}
          </div>
        </div>
      </template>
    </PageHeader>
    <div
      id="messages"
      class="h-full grow flex flex-col flex-auto justify-end space-y-4 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch mt-[100px]"
    >
      <ChatMessage
        v-for="message in filteredMessages"
        :key="message.id"
        :message="message"
      />
      <EmptyState
        v-if="messages.length == 0"
        title="It's quiet in here..."
        class="border-0"
      />
      <div ref="bottomOfChat" class="mt-20" />
    </div>

    <div class="mb-4 flex-auto">
      <ChatForm :conversation-id="route.params.conversationId" />
    </div>
  </div>
</template>
