import { defineStore } from "pinia";
import { UserNotificationService } from "@/services";

// Helper function
function filterByResourceType(notifications, resourceTypes) {
  return notifications.filter(
    (notification) => resourceTypes.includes(notification.resourceType)
  );
}

export const useUserNotificationStore = defineStore("userNotificationsStore", {
  state: () => {
    return {
      notifications: [],
    };
  },
  actions: {
    async fetchUserNotifications() {
      await UserNotificationService.fetchAll().then(
        ({ data }) => (this.notifications = data)
      );
    },
    async markUserNotificationAsSeen(id) {
      await UserNotificationService.markSeen(id).then(
        () => (this.fetchUserNotifications())
      );
    }
  },
  getters: {
    chatNotifications: (state) => {
      return filterByResourceType(state.notifications, ["Message"]);
    },
    resourceNotifications: (state) => {
      return filterByResourceType(state.notifications, ["Resource", "Video"]);
    },
    checkInNotifications: (state) => {
      return filterByResourceType(state.notifications, ["CheckIn"]);
    },
    findNotificationByResource: (state) => (resourceType, resourceId) => {
      return state.notifications.find((notification) => notification.resourceType === resourceType && notification.resourceId === resourceId);
    }
  }
});
