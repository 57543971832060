<script setup>
  import { computed, defineProps, defineEmits } from 'vue';

  const props = defineProps({
    color: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'outline', 'link', 'green', 'teal', 'red', 'brand-primary'].includes(value),
    },
    class: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
    },
  });

  const emit = defineEmits(['click']);
  const handleClick = () => emit('click');

  // Function to get classes based on color
  const getColorClasses = (color) => {
    const colorOptions = {
      'default': 'bg-slate-200 hover:bg-slate-300 text-slate-800',
      'outline': 'bg-white hover:bg-slate-100 text-slate-800 border border-slate-300 hover:border-slate-300',
      'link': 'bg-transparent hover:bg-transparent text-gray-800',
      'green': 'bg-green-600 hover:bg-green-700 text-white',
      'teal': 'bg-teal-500 hover:bg-teal-600 text-white',
      'brand-primary': 'bg-teal-500 hover:bg-teal-600 text-white',
      'red': 'bg-red-500 hover:bg-red-600 text-white',
    };
    return colorOptions[color] ?? colorOptions['default']; // Use the default gray if color is not in options
  };

  // Function to get classes based on size
  const getSizeClasses = (size) => {
    const sizeOptions = {
      'xs': 'px-2 py-1 text-xs',
      'sm': 'px-2 py-1 text-sm',
      'md': 'px-2.5 py-1.5 text-sm',
      'lg': 'px-3 py-2 text-sm',
      'xl': 'px-3.5 py-2.5 text-sm',
    };
    return sizeOptions[size];
  };

  // Computed property for final button classes
  const buttonClasses = computed(() => {
    const baseClasses = 'inline-flex items-center gap-x-1.5 rounded-md font-semibold shadow-sm';
    return `${baseClasses} ${getColorClasses(props.color)} ${getSizeClasses(props.size)} ${props.class}`;
  });
</script>

<template>
  <button
    type="button"
    :class="buttonClasses"
    @click="handleClick"
  >
    <slot />
  </button>
</template>
