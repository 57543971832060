import { defineStore } from "pinia";
import { CheckInService } from "@/services/";

export const useCheckInStore = defineStore("checkInStore", {
  state: () => {
    return {
      checkInTemplate: null,
      unReviewedCheckIns: [],
      draftCheckIn: null,
    };
  },
  actions: {
    setTemplate(template) {
      this.checkInTemplate = template;
    },
    async fetchDraftCheckIn(trainerId, trainingProgramId) {
      this.draftCheckIn = await CheckInService.findOrCreateDraft(
        trainerId,
        trainingProgramId
      );
    },
    async fetchUnReviewedCheckIns() {
      this.unReviewedCheckIns = await CheckInService.fetchAllUnReviewed().then(
        ({ data }) => data
      );
    },
  },
});
