<template>
  <div class="divide-y divide-gray-200 rounded-lg bg-white shadow mb-6">
    <div v-if="$slots.header" class="py-2 px-4 text-lg leading-6 font-bold text-white bg-teal-500 rounded-t-lg">
      <slot name="header" />
    </div>
    <div class="px-4 py-5 sm:p-6">
      <slot />
    </div>
    <div v-if="$slots.footer" class="px-4 py-4 sm:px-6">
      <slot name="footer" />
    </div>
  </div>
</template>
