<template>
  <div class="col-span-1 flex shadow rounded-md">
    <div
      :class="[
        props.bgColor,
        'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md',
      ]"
    >
      <font-awesome-icon
        :icon="`fa-solid ${props.icon}`"
        class="h-8 w-8"
      />
    </div>
    <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md">
      <div class="flex-1 pl-4 pr-2 py-2 text-sm">
        <p class="text-gray-500 font-medium capitalize">
          {{ props.label }}
        </p>
        <p class="text-gray-900 text-3xl font-semibold">
          {{ props.statValue }}<span class="text-xl"> {{ props.statUnit }}</span>
        </p>
      </div>
      <div v-if="previousStatDifference" class="py-3 pr-3">
        <AtomStatDifferenceIndicator
          :current-stat="props.statValue"
          :previous-stat="props.previousStatValue"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import AtomStatDifferenceIndicator from "./AtomStatDifferenceIndicator.vue";

const previousStatDifference = computed(() => {
  if (props.previousStatValue) {
    return props.statValue - props.previousStatValue;
  }
  return null;
});


const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  statValue: {
    type: Number,
    default: 0,
  },
  previousStatValue: {
    type: Number,
    default: null,
  },
  statUnit: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  bgColor: {
    type: String,
    default: "bg-gray-200",
  },
});
</script>
