<script setup>
  import { onBeforeMount, ref, computed } from "vue";
  import { useRoute } from "vue-router";
  import {
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
  } from "@headlessui/vue";
  import router from "@/routes/vueRouter.js";
  import PageHeader from "@/components/PageElements/PageHeader.vue";
  import PageSection from "@/components/Atoms/PageSection.vue";
  import { useCurrentUser } from "@/composables/useCurrentUser";
  import { CheckInTemplateService, ClientService, UserService } from "@/services";

  const user = ref(null);
  const route = useRoute();
  const currentUser = useCurrentUser();
  const checkInTemplates = ref([]);
  const breadCrumbs = ref(null);
  const trainers = ref([]);
  const isACoach = ref(user.value && user.value.roles.include("trainer"));

  onBeforeMount(() => {
    UserService.fetchActiveTrainers().then((res) => {
      trainers.value = res.data;
    });

    ClientService.findOne(route.params.id).then(({ data }) => {
      user.value = data;
      breadCrumbs.value = [
        {
          label: "My Clients",
          to: { name: "ActiveClientsPage" },
        },
        {
          label: user.value.fullName,
          to: {
            name: "ViewClientPage",
            params: { id: user.value ? user.value.id : "" },
          },
        },
        {
          label: "Edit",
          to: {
            name: "EditClientPage",
            params: { id: user.value ? user.value.id : "" },
          },
        },
      ];
    });

    CheckInTemplateService.fetchAllByTrainer({
      trainerId: currentUser.value.id,
    }).then(({ data }) => {
      checkInTemplates.value = data;
    });
  });

  const submitHandler = async function () {
    ClientService.update(user.value).then(() => {
      router.push({ name: "ViewClientPage", params: { id: user.value.id } });
    });
  };

  const coachOptions = computed(() => {
    return trainers.value.map((trainer) => {
      return {
        value: trainer.id,
        label: `${trainer.firstName} ${trainer.lastName}`,
      };
    });
  });

  const checkInDayOptions = computed(() => {
    return [
      {
        value: "1",
        label: "Monday",
      },
      {
        value: "2",
        label: "Tuesday",
      },
      {
        value: "3",
        label: "Wednesday",
      },
      {
        value: "4",
        label: "Thursday",
      },
      {
        value: "5",
        label: "Friday",
      },
      {
        value: "6",
        label: "Saturday",
      },
      {
        value: "7",
        label: "Sunday",
      },
    ];
  });
</script>

<template>
  <div>
    <PageHeader
      v-if="user"
      :bread-crumbs="breadCrumbs"
      :page-title="`Edit ${user.fullName}`"
    />

    <FormKit
      v-model="user"
      type="form"
      @submit="submitHandler"
    >
      <PageSection section-title="Profile">
        <FormKit
          type="text"
          name="email"
          label="Email"
        />
        <FormKit
          type="text"
          name="firstName"
          label="First Name"
        />
        <FormKit
          type="text"
          name="lastName"
          label="Last Name"
        />
      </PageSection>
      <PageSection section-title="Contact Information">
        <FormKit
          type="text"
          name="phone"
          label="Phone"
        />
        <!-- <FormKit type="text" name="firstName" label="First Name" />
				<FormKit type="text" name="lastName" label="Last Name" /> -->
      </PageSection>
      <PageSection section-title="Settings">
        <ul
          role="list"
          class="mt-2 divide-y divide-gray-200"
        >
          <FormKit
            type="select"
            name="checkInDayOfWeek"
            :value="user.checkInDayOfWeek"
            label="Check-In Day"
            :options="checkInDayOptions"
          />
          <FormKit
            type="select"
            label="Assigned Coach"
            placeholder="Select your coach"
            name="trainerId"
            :options="coachOptions"
          />
          <SwitchGroup
            as="li"
            class="py-4 flex items-center justify-between"
          >
            <div class="flex flex-col">
              <SwitchLabel
                as="p"
                class="text-sm font-medium text-gray-900"
                passive
              >
                Coach
              </SwitchLabel>
              <SwitchDescription class="text-sm text-gray-500">
                Make this user a coach
              </SwitchDescription>
            </div>
            <Switch
              v-model="isACoach"
              :class="[
                isACoach ? 'bg-teal-500' : 'bg-gray-200',
                'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500',
              ]"
            >
              <span
                aria-hidden="true"
                :class="[
                  isACoach ? 'translate-x-5' : 'translate-x-0',
                  'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                ]"
              />
            </Switch>
          </SwitchGroup>
        </ul>
      </PageSection>
    </FormKit>
  </div>
</template>
