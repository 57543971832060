import { defineStore } from "pinia";
import { ConversationService, MessageService } from "@/services";

export const useChatStore = defineStore("chat", {
  state: () => {
    return {
      conversations: [],
      unreadMessages: [],
      lastPoll: null,
    };
  },
  actions: {
    async fetchConversations() {
      ConversationService.fetchAllForUser()
        .then(({ data }) => {
          this.conversations.value = data.sort((a, b) => {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchUnreadMessages() {
      this.unreadMessages = await MessageService.fetchUnread()
        .then(({ data }) => {return data}
        );
    },
  },
});
