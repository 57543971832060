<script setup>
  import { computed } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import { storeToRefs } from "pinia";
  import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenu,
    IonMenuButton,
    IonPage,
    IonToolbar,
  } from "@ionic/vue";
  import { Disclosure, DisclosureButton } from "@headlessui/vue";
  import {
    BellIcon,
    ArrowLeftIcon,
    XCircleIcon,
  } from "@heroicons/vue/24/outline";
  import SearchDialogButton from "@/components/Atoms/SearchDialogButton.vue";
  import MainNav from "@/layouts/MainNav.vue";
  import { useAuthStore, useChatStore, useCheckInStore } from "@/stores";
  import { useCapacitor } from '@/composables/useCapacitor';

  const authStore = useAuthStore();
  const chatStore = useChatStore();
  const currentRoute = useRoute();
  const router = useRouter();
  const checkInStore = useCheckInStore();
  const { unReviewedCheckIns } = storeToRefs(checkInStore);
  const { unreadMessages } = storeToRefs(chatStore);

  const { isCapacitor } = useCapacitor();

  const showNotificationBadge = computed(function () {
    return unReviewedCheckIns?.value?.length + unreadMessages?.value?.length > 0;
  });

  const closeMenu = () => {
    const menu = document.querySelector("ion-menu");
    menu && menu.close();
  };
</script>
<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <div class="md:hidden">
    <ion-menu
      content-id="main-content"
      side="start"
    >
      <ion-header>
        <ion-toolbar class="bg-gray-800">
          <ion-menu-button class="close-btn">
            <XCircleIcon
              class="block h-6 w-6"
              aria-hidden="true"
            />
          </ion-menu-button>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding bg-gray-800">
        <MainNav @nav-click="closeMenu" />
      </ion-content>
    </ion-menu>
    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar class="bg-gray-800 pb-2">
          <Disclosure id="mobile-nav-bar">
            <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
              <div class="relative flex items-center justify-between">
                <div class="flex items-center px-2 lg:px-0">
                  <div class="flex-shrink-0">
                    <h1
                      v-if="currentRoute.fullPath === '/'"
                      class="font-sans text-white text-xl font-black mt-2"
                    >
                      <span class="text-teal-400"> AK </span>
                      <span class="font-extralight"> FIT </span>
                    </h1>
                    <button
                      v-if="currentRoute.fullPath != '/'"
                      type="button"
                      class="text-gray-400 hover:text-white cursor-pointer pt-2 px-2"
                      @click="router.back"
                    >
                      <ArrowLeftIcon
                        class="block h-6 w-6"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
                <div class="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">
                  <div class="max-w-lg w-full lg:max-w-xs">
                    <SearchDialogButton v-if="authStore.isTrainer" />
                  </div>
                </div>
                <div
                  v-if="showNotificationBadge"
                  class="lg:ml-4"
                >
                  <div class="flex items-center">
                    <DisclosureButton
                      class="inline-block relative flex-shrink-0 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:bg-slate-700 focus:text-white"
                    >
                      <span class="sr-only"> View notifications </span>
                      <BellIcon
                        class="h-6 w-6"
                        aria-hidden="true"
                      />
                      <span class="absolute top-1 right-1 block h-2 w-2 transform rounded-full bg-red-400" />
                    </DisclosureButton>
                  </div>
                </div>
              </div>
            </div>
          </Disclosure>
          <ion-buttons slot="end">
            <ion-menu-button />
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content
        class="ion-padding"
        v-if="isCapacitor"
      >
        <main class="flex flex-col flex-1 px-3 md:py-0 md:pr-8 md:pl-[285px]">
          <router-view />
        </main>
      </ion-content>
    </ion-page>
  </div>
</template>



<style scoped>
ion-menu {
  --width: 100%;
}
ion-content {
  --background: none;
}
ion-toolbar {
  --background: none;
}

ion-menu-button {
  color: #7d8591;
}
.close-btn {
  position: relative;
  right: -43%;
}
</style>
