import http from "@/services/http";

export const QuestionTemplateService = {
  create: ({ templateId, data }) => {
    return http.post(
      `check_in_templates/${templateId}/question_templates`,
      data
    );
  },

  update: ({ templateId, questionId, data }) => {
    return http.patch(
      `check_in_templates/${templateId}/question_templates/${questionId}`,
      data
    );
  },

  delete: ({ templateId, questionId }) => {
    return http.delete(
      `check_in_templates/${templateId}/question_templates/${questionId}`
    );
  },
};
