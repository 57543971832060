<template>
  <h1
    :id="props.id"
    :class="classes"
  >
    <slot />
  </h1>
</template>

<script setup>
import { defineProps, computed } from "vue";
const props = defineProps({
  id: {
    type: String,
    required: false,
    default: "page-header--title",
  },
  class: {
    type: String,
    required: false,
    default: "",
  },
});

const classes = computed(() => {
  return ['m-0 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight', props.class];
});
</script>
