import http from "@/services/http";

export const UserService = {
  create: (payload) => {
    return http.post("/users", payload);
  },
  fetchOne: (id) => {
    return http.get(`/users/${id}`);
  },
  fetchActiveTrainers: () => {
    return http.get("/trainers");
  },
};
