<template>
  <button
    :id="props.id"
    type="button"
    :class="classNames"
    @click="handleClick"
  >
    <HeroIcon v-if="props.icon" :icon="props.icon" classes="-ml-0.5 mr-1.5 h-5 w-5"/>
    <slot/>
  </button>
</template>

<script setup>
import {computed, defineEmits} from "vue";
import HeroIcon from "@/components/ui/HeroIcon";
const props = defineProps({
  id: {
    type: String,
    required: false,
    default: "",
  },
  classes: {
    type: String,
    required: false,
    default: null,
  },
  icon: {
    type: String,
    required: false,
    default: null,
  },
  primary: {
    type: Boolean,
    required: false,
    default: false,
  },
  secondary: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['clicked']);

const classNames = computed(() => {
  let finalClasses = ['inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm']

  if (props.primary) {
    finalClasses.push('bg-teal-600 text-white hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600')
  } else if (props.secondary) {
    finalClasses.push('border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500')
  } else {
    finalClasses.push('bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50')
  }

  if (props.classes) {
    finalClasses.push(props.classes)
  }

  return finalClasses
});

function handleClick() {
  emit('clicked');
}

</script>
@/components/ui/HeroIcon
