<script setup>
  import { ref, onMounted, computed, watch } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import PageHeader from "@/components/ui/PageHeader";
  import BasicButton from "@/components/ui/BasicButton";
  import IconWithLabel from "@/components/ui/IconWithLabel";
  import ClientList from "@/components/ClientList.vue";
  import { ClientService } from "@/services";

  const clients = ref([]);
  const currentPage = useRoute();
  const router = useRouter();

  const showArchivedClients = computed(() => {
    return currentPage.meta.showArchived;
  });

  const clientScope = computed(() => {
    return showArchivedClients.value ? "Archived" : "Active";
  });

  const fetchClients = () => {
    if (showArchivedClients.value == true) {
      ClientService.fetchAllArchivedByTrainer().then((res) => {
        clients.value = res.data;
      });
    } else {
      ClientService.fetchAllByTrainer().then((res) => {
        clients.value = res.data;
      });
    }
  };

  watch(currentPage, () => {
    fetchClients();
  });
  onMounted(() => {
    fetchClients();
  });
</script>
<template>
  <div>
    <PageHeader :title="`${clientScope} Clients`">
      <template #details>
        <IconWithLabel icon="users">
          Showing all {{ clientScope }} clients
        </IconWithLabel>
      </template>
      <template #actions>
        <BasicButton
          classes="mr-2"
          @click="router.push({ name: showArchivedClients ? 'ActiveClientsPage' : 'ArchivedClientsPage' })"
        >
          Show {{ !showArchivedClients ? "Archived" : "Active" }}
        </BasicButton>
        <BasicButton
          icon="plus"
          primary
          @click="router.push({ name: 'InviteClientPage' })"
        >
          Invite Client
        </BasicButton>
      </template>
    </PageHeader>
    <div class="bg-white shadow overflow-hidden sm:rounded-md mt-8">
      <ClientList :clients="clients" />
    </div>
  </div>
</template>


