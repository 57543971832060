<script setup>
  import { onMounted } from "vue";
  import { useRoute } from "vue-router";
  import { useUserNotificationStore } from "@/stores/UserNotificationsStore";
  import { useCurrentUser } from "./composables";
  import usePusher from "@/composables/usePusher";
  import useLaunchDarkly from "@/composables/useLaunchDarkly";
  import MainLayout from "@/layouts/MainLayout.vue";
  import { useCapacitor } from '@/composables/useCapacitor';

  const { isCapacitor } = useCapacitor();
  const userNotificationsStore = useUserNotificationStore();
  const currentUser = useCurrentUser();
  usePusher();
  useLaunchDarkly();

  onMounted(() => {
    console.log('Capacitor:', isCapacitor);
    if (currentUser.value){
      userNotificationsStore.fetchUserNotifications();
    }
  });

  const route = useRoute();
</script>

<template>
  <router-view v-if="route.meta.layout === 'Public'" />
  <MainLayout v-else />
</template>
