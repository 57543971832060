<script setup>
import { defineProps } from "vue";
import PageContainer from "@/components/Atoms/PageContainer.vue";
import { Heading3 } from "@/components/ui/Headings";

const props = defineProps({
  sectionTitle: {
    type: String,
    required: false,
    default: null,
  }
});
</script>

<template>
  <PageContainer>
    <Heading3 v-if="props.sectionTitle" >
      {{ props.sectionTitle }}
    </Heading3>
    <slot />
  </PageContainer>
</template>
@/components/ui/Headings
