<template>
  <header>
    <div class="mx-auto max-w-8xl pb-4 md:flex md:items-center md:justify-between mt-4">
      <div class="min-w-0 flex-1">
        <h1
          class="text-3xl font-bold leading-tight m-0 mb-4 md:mt-4 tracking-tight text-brand-primary-600"
          data-testid="page-title"
        >
          <slot />
        </h1>
      </div>
      <div class="mt-4 flex md:ml-4 md:mt-0">
        <slot name="header-actions" />
      </div>
    </div>
  </header>
</template>
