<script setup>
  import { ref, defineEmits } from "vue";
  import { useCurrentUser } from "@/composables";
  import { MessageService } from "@/services";

  const props = defineProps({
    conversationId: {
      type: String,
      required: true,
    },
  });

  const emit = defineEmits(["messageSent"]);
  const currentUser = useCurrentUser();
  const comment = ref(null);

  function onSubmit() {
    let payload = {
      body: comment.value,
      author_id: currentUser.id,
    };
    MessageService.create({
      conversationId: props.conversationId,
      message: payload,
    })
      .then(({ data }) => {
        emit("messageSent", data);
        comment.value = null;
      })
      .catch((error) => {
        console.log(error);
      });
  }
</script>

<template>
  <div class="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0">
    <div class="relative flex">
      <input v-model="comment" type="text" placeholder="Message..."
        class="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 border-0 bg-gray-200 rounded-md py-3 pr-[75px]"
        @keyup.enter="onSubmit" />
      <div class="absolute right-0 items-center inset-y-0">
        <button type="button"
          class="inline-flex items-center justify-center rounded-md px-4 py-3 transition duration-500 ease-in-out text-white bg-teal-500 hover:bg-teal-400 focus:outline-none"
          @click="onSubmit">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
            class="h-6 w-6 transform rotate-90">
            <path
              d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}
</style>
