export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/public/LoginPage"),
    meta: {
      public: true,
      layout: "Public",
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/pages/public/LoginPage"),
    meta: {
      layout: "Public",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/pages/public/RegistrationPage"),
    meta: {
      public: true,
      layout: "Public",
    },
  },
  {
    path: "/reset_password",
    name: "ResetPassword",
    component: () => import("@/pages/public/ResetPasswordPage"),
    meta: {
      public: true,
      layout: "Public",
    },
  },
  {
    path: "/forgot_password",
    name: "ForgotPassword",
    component: () => import("@/pages/public/ForgotPasswordPage"),
    meta: {
      public: true,
      layout: "Public",
    },
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("@/pages/public/404Page"),
    meta: {
      requiresAuth: false,
      layout: "Public",
    },
  },
];
