<script setup>
import { defineProps, computed } from "vue";
import { useUserAvatarQuery } from "@/queries/users/useUserAvatarQuery";
const props = defineProps({
  user: {
    type: Object,
    default: () => ({ firstName: "*", lastName: "*" }),
    required: true,
  },
  size: {
    type: String,
    default: () => "10",
  },
  classNames: {
    type: String,
    default: () => "",
  },
  backgroundColor: {
    type: String,
    default: () => "bg-teal-500",
  },
});

const userInitials = computed(() => {
  const { firstName, lastName } = props.user;
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
});

const { userAvatarUrl } = useUserAvatarQuery(props.user);
</script>

<template>
  <span class="user-avatar">
    <span
      v-if="user && !user.avatar && !userAvatarUrl"
      :class="`inline-flex items-center justify-center h-${size} w-${size} rounded-full ${props.backgroundColor} ${props.classNames}`"
    >
      <span class="text-base font-medium leading-none text-white uppercase">{{
        userInitials
      }}</span>
    </span>
    <img
      v-else
      :class="`h-${size} w-${size} rounded-full`"
      :src="userAvatarUrl"
      alt=""
    />
  </span>
</template>
