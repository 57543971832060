import { PushNotifications } from "@capacitor/push-notifications";

const initializePushNotifications = async () => {
  try {
    // Request and check permissions
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    // Register for push notifications
    await PushNotifications.register();

    // Add listeners
    await addListeners();
  } catch (error) {
    console.error("Error initializing push notifications:", error);
  }
};

const addListeners = async () => {
  PushNotifications.addListener("registration", async (token) => {
    const registrationToken = token.value;
    localStorage.setItem("push_notification_token", registrationToken);
  });

  PushNotifications.addListener("registrationError", (err) => {
    console.error("Registration error: ", err.error);
  });

  PushNotifications.addListener("pushNotificationReceived", async (notification) => {
    console.log("Push notification received: ", notification);
    const deliveredNotifications = await getDeliveredNotifications();
    console.log(deliveredNotifications.length);
  });

  PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
    console.log(
      "Push notification action performed",
      notification.actionId,
      notification.inputValue
    );
  });
};

const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  return notificationList;
};

export const PushNotificationService = {
  initializePushNotifications,
  addListeners,
  getDeliveredNotifications,
};
