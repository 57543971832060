<script setup>
import { defineProps } from "vue";

const props = defineProps({
  count: {
    type: Number,
    required: false,
    default: null,
  },
});
</script>

<template>
  <span
    v-if="props.count"
    class="ml-3 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full"
  >
    {{ props.count }}
  </span>
</template>
