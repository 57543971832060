<script setup>
  import { defineProps, computed } from "vue";
  import { useRouter } from "vue-router";
  import CountBadge from "@/components/Atoms/CountBadge";

  const router = useRouter();
  const props = defineProps({
    to: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    notificationsCount: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
      required: true
    }
  });

  const isActive = computed(() => {
    return router.currentRoute.value.path === router.resolve(props.to).path;
  });
  const iconClass = computed(() => {
    let classes = ["group-hover:text-gray-300"];
    classes.push(isActive.value ? "text-gray-300" : "text-gray-400");
    classes.push(props.icon)
    return classes
  });
</script>

<template>
  <span
    :id="props.id"
    :class="[
      isActive
        ? 'bg-gray-900 text-white'
        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
      'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer',
    ]"
    @click="router.push(props.to)"
  >
    <div
      v-if="props.icon"
      class="text-xl w-8 mr-3 flex-shrink-0 text-center"
    >
      <i
        :class="iconClass"
        aria-hidden="true"
      />
    </div>


    {{ props.label }}
    <CountBadge
      v-if="props.notificationsCount"
      :count="props.notificationsCount"
    />
  </span>
</template>
