export default [
  {
    path: "/clients/:id/workouts",
    name: "Workouts#Coach#ListClientWorkoutsPage",
    component: () => import("@/pages/Workouts/Coach/ListClientWorkoutsPage.vue"),
    meta:{
      requireCoach: true,
    }
  },
  {
    path: "/workouts",
    name: "Workouts",
    component: () => import("@/pages/Workouts/ClientIndexPage.vue"),
  },
  {
    path: "/workouts/:id/do",
    name: "DoWorkout",
    component: () => import("@/pages/Workouts/DoWorkoutPage.vue"),
  },
]
