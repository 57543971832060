import axios from "axios";
import { JwtService } from "./JwtService";
import { API_ROOT } from './paths/ApiRoot'
import { Capacitor } from "@capacitor/core";

let http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL + API_ROOT,
  headers: {
    "X-Client": Capacitor.isNativePlatform() ? "mobile" : "web",
    "X-Client-Version": process.env.VUE_APP_VERSION,
  },
});

http.interceptors.request.use(
  (config) => {
    const token = JwtService.currentJwt();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log("error in http interceptor");
    console.log(error)
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error in http interceptor");
    console.log(error)
    if (
      error.response.status === 401 &&
      error.response.data.errors == "Token has expired"
    ) {
      console.log("intercepting token expired");
      document.location.href = "/logout?security_timeout=true";
    }
    if (
      error.response.status === 404 &&
      error.response.data.errors == "Token did not match any user"
    ) {
      console.log("intercepting no user from token");
      document.location.href = "/logout?security_timeout=true";
    }
    if (
      error.response.status === 426 &&
      error.response.data.errors == "client_version_incompatible"
    ) {
      console.log("intercepting app version out of date");
      location.reload();
      return;
    }
    return error;
  }
);

export default http;
