import { defineStore } from "pinia";
import { ClientService } from "@/services";

export const useClientStore = defineStore("clientStore", {
  state: () => {
    return {
      clients: [],
      recentSearches: [],
      currentClient: null
    };
  },
  actions: {
    async fetchAndSetCurrentClient(id) {
      await ClientService.findOne(id).then(({ data }) => {
        this.currentClient = data;
      });
    },
    async fetchClients() {
      await ClientService.fetchAllByTrainer().then(
        ({ data }) => (this.clients = data)
      );
    },
  },
});
