export function camelCaseToHuman(str) {
  return str.replace(/([A-Z])/g, " $1").trim();
}

export function greetByTime(name) {
  let time = new Date().getHours();
  if (time < 12) {
    return "Good morning, " + name;
  } else if (time < 18) {
    return "Good afternoon, " + name;
  } else {
    return "Good evening, " + name;
  }
}

export function getDate(date) {
  const dat = new Date(date);
  const month = dat.getMonth() + 1;
  const day = dat.getDate();
  const year = dat.getFullYear().toString().substr(-2);
  return [month, day, year].join("/");
}

export function formatDate(date) {
  const dat = new Date(date);
  const month = dat.getMonth() + 1;
  const day = dat.getDate();
  const year = dat.getFullYear();
  return [month, day, year].join("/");
}
