<script setup>
const props = defineProps({
  id: {
    type: String,
    required: false,
    default: "",
  },
});
</script>
<template>
  <h2 :id="props.id" class="m-0 text-2xl leading-8 font-semibold tracking-tight text-gray-900 capitalize">
    <slot />
  </h2>
</template>
