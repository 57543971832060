<script setup>
  import { computed } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import { storeToRefs } from "pinia";
  import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
  import {
    BellIcon,
    Bars3Icon,
    XCircleIcon,
    ArrowLeftIcon,
  } from "@heroicons/vue/24/outline";
  import SearchDialogButton from "@/components/Atoms/SearchDialogButton.vue";
  import MainNav from "@/layouts/MainNav.vue";
  import { useAuthStore, useChatStore, useCheckInStore } from "@/stores";

  const authStore = useAuthStore();
  const chatStore = useChatStore();
  const currentRoute = useRoute();
  const router = useRouter();
  const checkInStore = useCheckInStore();
  const { unReviewedCheckIns } = storeToRefs(checkInStore);
  const { unreadMessages } = storeToRefs(chatStore);

  const showNotificationBadge = computed(function () {
    return unReviewedCheckIns.value.length + unreadMessages.value.length > 0;
  });
</script>

<template>
  <Disclosure
    id="mobile-nav-bar"
    v-slot="{ open, close }"
    as="nav"
    class="bg-gray-800 md:hidden block fixed top-0 left-0 right-0 z-50 pt-safe"
  >
    <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="flex items-center px-2 lg:px-0">
          <div class="flex-shrink-0">
            <h1
              v-if="currentRoute.fullPath === '/'"
              class="font-sans text-white text-xl font-black"
            >
              <span class="text-teal-400">AK</span>
              <span class="font-extralight">FIT</span>
            </h1>
            <button
              v-if="currentRoute.fullPath != '/'"
              type="button"
              class="text-gray-400 hover:text-white cursor-pointer pt-2 px-2"
              @click="router.back"
            >
              <ArrowLeftIcon
                class="block h-6 w-6"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
        <div class="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">
          <div class="max-w-lg w-full lg:max-w-xs">
            <SearchDialogButton v-if="authStore.isTrainer" />
          </div>
        </div>
        <div
          v-if="showNotificationBadge"
          class="lg:ml-4"
        >
          <div class="flex items-center">
            <DisclosureButton
              class="inline-block relative flex-shrink-0 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:bg-slate-700 focus:text-white"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon
                class="h-6 w-6"
                aria-hidden="true"
              />
              <span
                class="absolute top-1 right-1 block h-2 w-2 transform rounded-full bg-red-400"
              />
            </DisclosureButton>
          </div>
        </div>
        <div class="flex lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:bg-slate-700"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon
              v-if="!open"
              class="block h-6 w-6"
              aria-hidden="true"
            />
            <XCircleIcon
              v-else
              class="block h-6 w-6"
              aria-hidden="true"
            />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="lg:hidden pb-5">
      <MainNav @nav-click="close()" />
    </DisclosurePanel>
  </Disclosure>
</template>

<style scoped>
.pt-safe {
  padding-top: env(safe-area-inset-top);
}
</style>
